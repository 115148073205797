import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Typography,
    Paper
 } from "@mui/material";

const DataControlPanel = ({}) => {

// Placeholder statuses
  const [syncData, setSyncData] = useState([
    {
        id: 1,
        name: "Procore Team Sync",
        description: "Synchronize project team details from Procore.",
        lastSync: "11/27/2024 at 6:00PM CDT",
      },
      {
        id: 2,
        name: "Budget Data Sync",
        description: "Synchronize budget data for all active projects from Procore.",
        lastSync: "10/10/2024 at 4:00PM CDT",
      },
      {
        id: 3,
        name: "Project Info Sync",
        description: "Update project metadata and statuses.",
        lastSync: "10/15/2024 at 1:00PM CDT",
      },
  ]);

// Placeholder sync -- api's will be called from here
const handleForceSync = (id) => {
    const now = new Date().toLocaleTimeString();
    setSyncData((prevData) =>
      prevData.map((item) =>
        item.id === id
          ? { ...item, lastSync: `Last synced at ${now}` }
          : item
      )
    );
  };

    return (
        <>
         <Typography variant="h6" gutterBottom sx={{ padding: 2 }}>
          Data Control Panel BETA - In Development
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: 3 }}>
        <Table>
          <TableHead style={{backgroundColor: 'lightgrey'}}>
            <TableRow>
              <TableCell>Sync Name</TableCell>
              <TableCell className="data-control-headers">Description</TableCell>
              <TableCell className="data-control-headers" align="center">Force Sync</TableCell>
              <TableCell className="data-control-headers" align="center">Last Sync</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {syncData.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleForceSync(row.id)}
                  >
                    Sync Now
                  </Button>
                </TableCell>
                <TableCell align="center">{row.lastSync}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </>
    );
  };
  

export default DataControlPanel;