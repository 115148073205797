import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, MenuItem, Select, FormControl, InputLabel
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CardContent } from '@mui/material';

const formatDollar = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const useStyles = makeStyles({
  table: {
    minWidth: 500,
    borderRadius: '15px',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#093d61',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const OverUnderSummaryChart = ({ budgetData, isModal, selectedSnapshots, selectedProject, apiKey, apiHeader, csrfToken, HUB_API_DOMAIN_NAME  }) => {
  const classes = useStyles();

  // Initialize state Hooks at the top level
  const [firstSnapshot, setFirstSnapshot] = useState('');
  const [secondSnapshot, setSecondSnapshot] = useState('');
  const [loading, setLoading] = useState(true);

  // API Setters for case 2
  const [topPickupsData, setTopPickupsData] = useState([]);
  const [topLossesData, setTopLossesData] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);

  useEffect(() => {
    if (selectedSnapshots && selectedSnapshots.length > 0) {
      setLoading(false);
      if (!firstSnapshot) {
        // Automatically set to first selected snapshot
        setFirstSnapshot(selectedSnapshots[0]);
      }
    }
  }, [selectedSnapshots]);

  // Fetch for Case #2 -- 2 Snapshots selected.
  useEffect(() => {
    if (firstSnapshot && secondSnapshot && selectedProject) {
      setApiLoading(true);
      setApiError(null);
      // Headers
      const headers = new Headers({
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken,
        'Auth': apiHeader,
        'api-key': apiKey,
      });
      // Payload
      const payload = {
        project_id: selectedProject.id,
        snapshot1_id: firstSnapshot.id,
        snapshot2_id: secondSnapshot.id,
        csrf_token: csrfToken,
      };
      // Make the API call using fetch
      fetch(`${HUB_API_DOMAIN_NAME}/phd/get_projected_over_under_details/`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
        credentials: 'include',
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          if (data.error) {
            setApiError(data.error);
          } else {
            setTopPickupsData(data.top_10 || []);
            setTopLossesData(data.bottom_10 || []);
          }
          setApiLoading(false);
        })
        .catch(error => {
          console.error('Error fetching over under details:', error);
          setApiError(error.toString());
          setApiLoading(false);
        });
    } else {
      console.log("Missing info on comparison call.")
    }
  }, [secondSnapshot]); 
  // Only fire when second snapshot is changed... For now. 

  if (selectedProject && (!budgetData || budgetData?.bvt_cost_code_mapping_available === false)) {
    return (
      <Typography style={{ textAlign: 'center', color: 'red', marginLeft: '25px', marginRight: '25px', marginTop: '10px', marginBottom: '10px' }}>
        Cost Code mapping is not available for this project. Please upgrade your BVT workbook to the latest version in order to view this data.
      </Typography>
    );
  }

   // Use budgetData.over_under_summary when secondSnapshot is not selected
  const rows = budgetData?.over_under_summary || { top_10: [], bottom_10: [] };
  const totalTopPickups = rows.top_10 || [];
  const totalTopLosses = rows.bottom_10 || [];

  const combinedRows = [...(rows.top_10 || []), ...(rows.bottom_10 || [])];

  // Filtered snapshots for second dropdown based on first snapshot - ensuring it's in the future
  const availableSnapshots = selectedSnapshots.filter((snapshot) => {
    return firstSnapshot ? new Date(snapshot.created_at) > new Date(firstSnapshot.created_at) : true;
  });

  const handleFirstSnapshotChange = (event) => {
    setFirstSnapshot(event.target.value);
    // Reset 2nd snapshot whenever the 1st changes
    setSecondSnapshot('');
  };

  const handleSecondSnapshotChange = (event) => {
    setSecondSnapshot(event.target.value);
  };

  return (
    <CardContent>
      {(!budgetData ||
        !budgetData.over_under_summary ||
        (combinedRows.length === 0 && isModal === false) ||
        (totalTopPickups.length === 0 && totalTopLosses.length === 0 && isModal === true)
      ) ? (
        <Typography style={{ textAlign: 'center' }} className="key-project-info-body">--</Typography>
      ) : isModal === false ? (
        // Case #1 - Full table shown in component -- POST request fired off of 2nd snapshot changes
        <TableContainer component={Paper} style={{ maxHeight: '250px' }}>
          <Table className={classes.table} aria-label="over-under summary table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Value</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {combinedRows.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {formatDollar(row.value)}
                  </StyledTableCell>
                  <StyledTableCell>{row.description}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        // Case #2 - Modal is opened and table is split into top-10 lists
        <>
          <div style={{ display: 'flex', gap: '20px', marginBottom: '20px', marginLeft: '10px', marginRight: '10px' }}>
            <FormControl style={{ flex: 1, marginBottom: '20px' }}>
              <InputLabel>First Snapshot</InputLabel>
              <Select value={firstSnapshot} onChange={handleFirstSnapshotChange}>
                {selectedSnapshots.map((snapshot) => (
                  <MenuItem key={snapshot.id} value={snapshot}>
                    {snapshot.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              style={{ flex: 1, marginBottom: '20px' }}
              disabled={!firstSnapshot}
            >
              <InputLabel>Second Snapshot</InputLabel>
              <Select value={secondSnapshot} onChange={handleSecondSnapshotChange}>
                {availableSnapshots.map((snapshot) => (
                  <MenuItem key={snapshot.id} value={snapshot}>
                    {snapshot.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {apiLoading && (
            <Typography variant="body1" style={{ textAlign: 'center' }}>
              Loading data...
            </Typography>
          )}

          {apiError && (
            <Typography variant="body1" style={{ textAlign: 'center', color: 'red' }}>
              Error: {apiError}
            </Typography>
          )}

          {!secondSnapshot && !apiLoading && !apiError && (
            <>
              <Typography variant="body1" style={{ textAlign: 'center', marginTop: '30px'}}>
                {`Displaying the Top Pickups and Top Losses form baseline to latest snapshot. `}
              </Typography>

              <div className={classes.container}>
                {totalTopPickups.length > 0 && (
                  <>
                    <Typography variant="h6" className={classes.tableTitle} style={{ marginBottom: '10px' }}>
                      Top Pickups
                    </Typography>
                    <TableContainer component={Paper} style={{ maxHeight: '250px' }}>
                      <Table className={classes.table} aria-label="Top Pickups table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Value</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {totalTopPickups.map((row, index) => (
                            <TableRow key={index}>
                              <StyledTableCell component="th" scope="row">
                                {formatDollar(row.value)}
                              </StyledTableCell>
                              <StyledTableCell>{row.description}</StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
                {totalTopLosses.length > 0 && (
                  <>
                    <Typography variant="h6" className={classes.tableTitle} style={{ marginBottom: '10px', marginTop: '30px' }}>
                      Top Losses
                    </Typography>
                    <TableContainer component={Paper} style={{ maxHeight: '250px' }}>
                      <Table className={classes.table} aria-label="Top Losses table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Value</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {totalTopLosses.map((row, index) => (
                            <TableRow key={index}>
                              <StyledTableCell component="th" scope="row">
                                {formatDollar(row.value)}
                              </StyledTableCell>
                              <StyledTableCell>{row.description}</StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </>
          )}

          {secondSnapshot && !apiLoading && !apiError && (
            <>
              <Typography variant="body1" style={{ textAlign: 'center' }}>
                {`Displaying Variance Between "${firstSnapshot.name}" and "${secondSnapshot.name}"`}
              </Typography>

              <div className={classes.container}>
                {topPickupsData.length > 0 && (
                  <>
                    <Typography variant="h6" className={classes.tableTitle} style={{ marginBottom: '10px' }}>
                      Top Pickups
                    </Typography>
                    <TableContainer component={Paper} style={{ maxHeight: '250px' }}>
                      <Table className={classes.table} aria-label="Top Pickups table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Value</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {topPickupsData.map((row, index) => (
                            <TableRow key={index}>
                              <StyledTableCell component="th" scope="row">
                                {formatDollar(row.value)}
                              </StyledTableCell>
                              <StyledTableCell>{row.description}</StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
                {topLossesData.length > 0 && (
                  <>
                    <Typography variant="h6" className={classes.tableTitle} style={{ marginBottom: '10px', marginTop: '30px' }}>
                      Top Losses
                    </Typography>
                    <TableContainer component={Paper} style={{ maxHeight: '350px' }}>
                      <Table className={classes.table} aria-label="Top Losses table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Value</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {topLossesData.map((row, index) => (
                            <TableRow key={index}>
                              <StyledTableCell component="th" scope="row">
                                {formatDollar(row.value)}
                              </StyledTableCell>
                              <StyledTableCell>{row.description}</StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}
    </CardContent>
  );
};

export default OverUnderSummaryChart;
