import {useState, useEffect} from "react";
import bonusToolIcon from '../static/bonus_tool_icon.svg';
import "../static/project-dashboard-styles.css"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import QuizIcon from '@mui/icons-material/Quiz';

import { Typography, CardContent, Link, FormControlLabel, Checkbox } from "@mui/material";
import ProjectExternalLinks from "./ProjectLinksComponent";
import ProjectSelect from "./ProjectSelectComponent";
import CompanySelect from "./CompanySelectorComponent";
import ProjectSettingsModal from "./ProjectSettingsContainerModal";
import DivisionSelect from "./DivisionSelectorComponent";
import ProjectStageSelect from "./ProjectStageSelectorComponent";
import OpsLeaderSelect from "./OpsLeaderSelectorComponent";
import ProjectTypeSelect from "./ProjectTypeSelectorComponent";
import AccountSelect from "./AccountSelectorComponent";
import FAQModal from "./FAQComponent";
import BudgetSnapshotSelector from "./BudgetSnapshotSelectorComponent";

const HeaderComponent = ({ 
    selectedProject,
    projectData,
    today_date,
    selectedCompany,
    companies,
    handleCompanySelectChange,
    filteredProjects,
    handleProjectSelectChange,
    setSelectedProjectId,
    csrfToken, 
    selectedSnapshots,
    handleSelectionChange,
    handleModalClose,
    selectedProjectId,
    initialSnapshots, 
    portfolioView,
    setPortfolioView,
    divisionOptions,
    selectedDivisions,
    setSelectedDivisions,
    stageOptions,
    selectedStages,
    setSelectedStages,
    opsLeaderOptions,
    selectedOpsLeaders,
    setSelectedOpsLeaders,
    projectTypeOptions,
    selectedProjectTypes,
    setSelectedProjectTypes,
    accountOptions,
    selectedAccounts,
    setSelectedAccounts,
    handlePortfolioSearch,
    apiHeader,
    apiKey,
    HUB_API_DOMAIN_NAME, 
    settingsData,
    
    }) => {
    
    // Latest Snapshot for Data Updated
    const [mostRecentCreatedAt, setMostRecentCreatedAt] = useState(null);
    const [isCompletedChecked, setIsCompletedChecked] = useState(false);

    const handleCheckboxChange = (event) => {
      setIsCompletedChecked(event.target.checked);
    };

    useEffect(() => {
        if (selectedSnapshots && selectedSnapshots.length > 0) {
            const mostRecent = selectedSnapshots.reduce((latest, snapshot) => {
                return new Date(snapshot.created_at) > new Date(latest) ? snapshot.created_at : latest;
            }, selectedSnapshots[0].created_at);

            setMostRecentCreatedAt(new Date(mostRecent).toLocaleDateString());
        } else {
            setMostRecentCreatedAt(null);
        }
    }, [selectedSnapshots]);

    
    return (
            <CardContent className="title-block-content">
                <div className="dashboard-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'column', gap: '1px' }}>

                    <div className="upper-controls-container" style={{display: "flex", flexDirection: 'row', justifyContent: 'flex-start'}}>

                    {/* View Control Buttons */}
                    <div className="portfolio-view-control-btns-container">
                        <p style={{marginRight: '4px'}}>View Control:</p>
                        <label className="card-title-2" style={{ marginRight: '4px', color: 'white' }}>
                        <input
                            type="radio"
                            value="project"
                            checked={portfolioView === false}
                            onChange={() => setPortfolioView(false)}
                        />
                        Project
                        </label>
                        <label className="card-title-2" style={{color: 'white'}}>
                        <input
                            type="radio"
                            value="portfolio"
                            checked={portfolioView === true}
                            onChange={() => setPortfolioView(true)}
                        />
                        Portfolio
                        </label>
                    </div>

                    {/* Checkbox */}
                    {!portfolioView && (
                        <div
                        className='exclude-completed-container'
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%',
                            fontSize: 'smallest',
                            marginBottom: '11px',
                        }}
                        >
                        {/* <FormControlLabel
                            control={
                            <Checkbox
                                checked={isCompletedChecked}
                                onChange={handleCheckboxChange}
                                sx={{
                                color: 'white',
                                '&.Mui-checked': {
                                    color: 'white',
                                },
                                }}
                            />
                            }
                            label="Exclude Inactive Projects"
                            className="exclude-completed-label"
                            sx={{
                                margin: 0,
                                labelPlacement: 'end',
                              }}
                              componentsProps={{
                                typography: {
                                  sx: {
                                    marginLeft: '-2px',
                                  },
                                },
                              }}
                        /> */}
                        </div>
                    )}

                    </div>

                       {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', fontSize: 'smallest' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isCompletedChecked}
                                    onChange={(e) => setIsCompletedChecked(e.target.checked)}
                                    sx={{
                                        color: 'white',
                                        '&.Mui-checked': {
                                            color: 'white',
                                        },
                                    }}
                                />
                            }
                            label="Exclude completed"
                            className="exclude-completed-label"
                        // /> */}
        {/* </div> */}
                
            
                <div className="dashboard-selectors-container">
                    <div className='dashboard-selector' style={{ flex: 1 }}>
                        <CompanySelect
                            selectedCompany={selectedCompany}
                            options={companies}
                            handleSelectChange={handleCompanySelectChange}
                            portfolioView={portfolioView}
                        />
                    </div>
                    {/* Conditionally show the project dropdown or new dropdowns based on portfolioView */}
                    {portfolioView ? (
                        <>
                            <div className='dashboard-selector' style={{ flex: 1 }}>
                                <DivisionSelect
                                    options={divisionOptions}
                                    selectedValues={selectedDivisions}
                                    onChange={(newValues) => {
                                        setSelectedDivisions(newValues);
                                    }}
                                />
                            </div>
                            <div className='dashboard-selector' style={{ flex: 1 }}>
                                <ProjectStageSelect
                                    options={stageOptions}
                                    selectedValues={selectedStages}
                                    onChange={(newValues) => {
                                        setSelectedStages(newValues);
                                    }}
                                />
                            </div>
                            <div className='dashboard-selector' style={{ flex: 1 }}>
                                <OpsLeaderSelect
                                    options={opsLeaderOptions}
                                    selectedValues={selectedOpsLeaders}
                                    onChange={(newValues) => {
                                        setSelectedOpsLeaders(newValues);
                                    }}
                                />
                            </div>
                            <div className='dashboard-selector' style={{ flex: 1 }}>
                                <ProjectTypeSelect
                                    options={projectTypeOptions}
                                    selectedValues={selectedProjectTypes}
                                    onChange={(newValues) => {
                                        setSelectedProjectTypes(newValues);
                                    }}
                                />
                            </div>
                            <div className='dashboard-selector' style={{ flex: 1 }}>
                                <AccountSelect
                                    options={accountOptions}
                                    selectedValues={selectedAccounts}
                                    onChange={(newValues) => {
                                        setSelectedAccounts(newValues);
                                    }}
                                />
                            </div>
                        
                        <div className="csr-report-link-container" style={{ alignSelf: 'center' }}>
                                {selectedCompany ?(
                                    <div style={{display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent:'center'}}>
                                      <a href={`https://hub.arcomurray.com/reports/confidentiality/view/?company=${selectedCompany.company}`} className="csr-report-link" target="_blank" rel="noopener noreferrer">
                                        Confidentiality Sales Report
                                        <OpenInNewIcon style={{ marginLeft: '5px', fontSize: '20px', verticalAlign: 'middle' }} />
                                        </a>
                                    </div>
                                ) : (
                                    null
                                )}
                        </div>
                            
                        </>
                    ) : (
                        <div className='dashboard-selector' style={{ flex: 1 }}>
                            <ProjectSelect
                                filteredProjects={filteredProjects}
                                selectedProject={selectedProject}
                                handleProjectSelectChange={handleProjectSelectChange}
                                setSelectedProjectId={setSelectedProjectId}
                                csrfToken={csrfToken}
                                isCompletedChecked={isCompletedChecked}
                        />
                        </div>
                    )}
                </div>
            </div>
            {!portfolioView && (
                selectedProject ? (
                    <div className='project-information-container'>
                    <div className='project-account-container'>
                        <Typography variant='h5' className="titillium-web-base project-header-title-text-large">{selectedProject.name}</Typography>
                        <Typography variant='h5' className="titillium-web-base project-header-title-text-large">({projectData.project_data?.account_name})</Typography>
                    </div>
                    <Typography variant='h5' className="titillium-web-base project-header-title-text-large">ARCO ID: {projectData.project_data?.arco_id}</Typography>
                    </div>
                ) : (
                    <div className='project-information-container' style={{marginBottom: '20px'}}>
                    <Typography variant='h6' className="titillium-web-base project-header-title-text" style={{ marginTop: '20px', textAlign: 'center' }}>
                        Please Select a Company and Project to View Data.
                    </Typography>
                    </div>
                )
                )}
                
          
                {!portfolioView && selectedProject && (
                    <div className="project-links-container" style={{ flex: 1 }}>
                        {selectedProject && selectedSnapshots && selectedSnapshots.length > 0 && mostRecentCreatedAt && (
                        <Typography variant='h6' className="titillium-web-base project-header-title-text">
                            Latest Budget Snapshot: {mostRecentCreatedAt}
                        </Typography>
                        )}

                        {selectedProject && (
                        <div className="bonus-tool-container" style={{ display: 'none' }}>
                            <Typography variant='h6' className="titillium-web-base">
                            Bonus Model
                            </Typography>
                            <Link href='#' target="_blank" variant='h6' className="titillium-web-base">
                            <img src={bonusToolIcon} className="bonus-tool-icon" />
                            </Link>
                        </div>
                        )}

                        <ProjectExternalLinks
                            selectedProjectId={selectedProjectId}
                            projectData={projectData}
                        />

                        {selectedProject && selectedSnapshots && selectedSnapshots.length > 0 && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Typography variant="subtitle1" className="card-title" style={{ color: 'white', textAlign: 'left', verticalAlign: 'top' }}>
                            Project Settings
                            </Typography>
                            <ProjectSettingsModal
                                Component={BudgetSnapshotSelector}
                                settingsData={settingsData}
                                initialSnapshots={initialSnapshots}
                                title={'Budget Snapshot Selector'}
                                selectedSnapshots={selectedSnapshots}
                                onSelectionChange={handleSelectionChange}
                                handleModalClose={handleModalClose}
                                apiHeader={apiHeader}
                                apiKey={apiKey}
                                csrfToken={csrfToken}
                                HUB_API_DOMAIN_NAME={HUB_API_DOMAIN_NAME}
                                selectedProjectId={selectedProjectId}
                            />
                        </div>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Typography variant="subtitle1" className="card-title" style={{ color: 'white', textAlign: 'left', verticalAlign: 'top' }}>
                            FAQs
                            </Typography>
                            <FAQModal 
                                title={'Frequently Asked Questions'}
                            ></FAQModal>
                        
                        </div>

                    </div>
                    )}
                 {!portfolioView && !selectedProject && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Typography variant="subtitle1" className="card-title" style={{ color: 'white', textAlign: 'left', verticalAlign: 'top' }}>
                        FAQs
                        </Typography>
                        <FAQModal 
                            title={'Frequently Asked Questions'}
                        ></FAQModal>
                    
                    </div>
                     
                 )}

        </CardContent>
    );
};

export default HeaderComponent;