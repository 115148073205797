import React from 'react';

import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { CardContent } from '@mui/material';
// PDF Download Library
import 'jspdf-autotable';
import { formatDollar, formatPercentage, colorCodeRed } from './utilityFunctions';

const useStyles = makeStyles({
    table: {
        minWidth: 500,
        whiteSpace: 'nowrap',
    },
    tableContainer: {
        overflowX: 'auto',
    },
    statusIcon: {
        verticalAlign: 'middle',
        marginRight: '8px',
    },
    red: {
        color: 'red',
    },
    green: {
        color: 'green',
    },
    copyButton: {
        margin: '10px 0',
    },
    excelButton: {
        backgroundColor: '#1e613b',
        color: 'white',
        margin: '10px 0px 10px 10px',
        '&:hover': {
            backgroundColor: '#1e613b',
        },
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#093d61',
        color: theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: 16,
        whiteSpace: 'nowrap',
        width: '16.66%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
        width: '16.66%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
}))(TableCell);

const StyledTableRow = withStyles({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f0f0f0', 
        },
    },
})(TableRow);

  
const BVTTablesComponent = ({ budgetData, isModal, selectedSnapshots }) => {
    const classes = useStyles();
    if (!budgetData || budgetData.length === 0 || !budgetData.baseline_contract_summary?.contract_structure || !budgetData.penultimate_contract_summary?.contract_structure || !budgetData.current_contract_summary?.contract_structure
    ) {
        return <Typography style={{ textAlign: 'center' }}>--</Typography>;
    }

    // Extract baseline, penultimate, and current contract summaries from budgetData
    const { baseline_contract_summary: baseline, penultimate_contract_summary: penultimate, current_contract_summary: current } = budgetData;

    // Helper function to calculate variance
    const calculateVariance = (a, b) => {
        if (typeof a === 'number' && typeof b === 'number') {
            return b - a;
        } else {
            return null;
        }
    };

    // Initialize separate GMP and LS Totals for Cost and Budget
    let lumpSumTotals = { baseline: 0, penultimate: 0, current: 0 };
    let gmpTotals = { baseline: 0, penultimate: 0, current: 0 };

    let lumpSumCosts = { baseline: 0, penultimate: 0, current: 0 };
    let gmpCosts = { baseline: 0, penultimate: 0, current: 0 };

    // Variance variables
    let variancePenultimateToCurrentLumpSum = 0,
        varianceBaselineToCurrentLumpSum = 0;
    let variancePenultimateToCurrentGMP = 0,
        varianceBaselineToCurrentGMP = 0;

    let variancePenultimateToCurrentLumpSumCosts = 0,
        varianceBaselineToCurrentLumpSumCosts = 0;
    let variancePenultimateToCurrentGMPCosts = 0,
        varianceBaselineToCurrentGMPCosts = 0;

    // Extract Budet & Cost Items
    const currentBudgetItems = Object.keys(current.contract_structure.Budget || {});
    const currentCostItems = Object.keys(current.contract_structure.Cost || {});

    const calculateTotals = (contractSummary, type) => {
        const items = Object.keys(contractSummary.contract_structure[type] || {});
        return items.reduce(
            (totals, item) => {
                const value = contractSummary.contract_structure[type][item] || 0;
                if (item.includes('LS')) {
                    totals.lumpSum += value;
                } else if (item.includes('GMP')) {
                    totals.gmp += value;
                }
                return totals;
            },
            { lumpSum: 0, gmp: 0 }
        );
    };

    // Calculate Lump Sum and GMP totals for Budget
    const baselineBudgetTotals = calculateTotals(baseline, 'Budget');
    const penultimateBudgetTotals = calculateTotals(penultimate, 'Budget');
    const currentBudgetTotals = calculateTotals(current, 'Budget');

    lumpSumTotals.baseline = baselineBudgetTotals.lumpSum;
    lumpSumTotals.penultimate = penultimateBudgetTotals.lumpSum;
    lumpSumTotals.current = currentBudgetTotals.lumpSum;

    gmpTotals.baseline = baselineBudgetTotals.gmp;
    gmpTotals.penultimate = penultimateBudgetTotals.gmp;
    gmpTotals.current = currentBudgetTotals.gmp;

    // Calculate variances for Lump Sum Budget
    variancePenultimateToCurrentLumpSum = calculateVariance(lumpSumTotals.penultimate, lumpSumTotals.current);
    varianceBaselineToCurrentLumpSum = calculateVariance(lumpSumTotals.baseline, lumpSumTotals.current);

    // Calculate variances for GMP Budget
    variancePenultimateToCurrentGMP = calculateVariance(gmpTotals.penultimate, gmpTotals.current);
    varianceBaselineToCurrentGMP = calculateVariance(gmpTotals.baseline, gmpTotals.current);

    const baselineCostTotals = calculateTotals(baseline, 'Cost');
    const penultimateCostTotals = calculateTotals(penultimate, 'Cost');
    const currentCostTotals = calculateTotals(current, 'Cost');

    lumpSumCosts.baseline = baselineCostTotals.lumpSum;
    lumpSumCosts.penultimate = penultimateCostTotals.lumpSum;
    lumpSumCosts.current = currentCostTotals.lumpSum;

    gmpCosts.baseline = baselineCostTotals.gmp;
    gmpCosts.penultimate = penultimateCostTotals.gmp;
    gmpCosts.current = currentCostTotals.gmp;

    // Calculate variances for Lump Sum Costs
    variancePenultimateToCurrentLumpSumCosts = calculateVariance(lumpSumCosts.penultimate, lumpSumCosts.current);
    varianceBaselineToCurrentLumpSumCosts = calculateVariance(lumpSumCosts.baseline, lumpSumCosts.current);

    // Calculate variances for GMP Costs
    variancePenultimateToCurrentGMPCosts = calculateVariance(gmpCosts.penultimate, gmpCosts.current);
    varianceBaselineToCurrentGMPCosts = calculateVariance(gmpCosts.baseline, gmpCosts.current);


    // Initialize totals
    // Budget
    let baselineTotal = 0, penultimateTotal = 0, currentTotal = 0;
    let variancePenultimateToCurrentTotal = 0, varianceBaselineToCurrentTotal = 0;
    let baselineAllowanceTotal = 0, penultimateAllowanceTotal = 0, currentAllowanceTotal = 0;
    let baselineMarkupsTotal = 0, penultimateMarkupsTotal = 0, currentMarkupsTotal = 0;
    let baselineTotalBudgetContract = 0, penultimateTotalBudgetContract = 0, currentTotalBudgetContract = 0;

    // Costs
    let baselineCostTotal = 0, penultimateCostTotal = 0, currentCostTotal = 0;
    let variancePenultimateToCurrentTotalCost = 0, varianceBaselineToCurrentTotalCost = 0;
    let baselineCostAllowanceTotal = 0, penultimateCostAllowanceTotal = 0, currentCostAllowanceTotal = 0;
    let baselineCostMarkupsTotal = 0, penultimateCostMarkupsTotal = 0, currentCostMarkupsTotal = 0;
    let baselineTotalCostContract = 0, penultimateTotalCostContract = 0, currentTotalCostContract = 0;

    // Over/Under Totals
    let baselineOverUnderTotal = 0, penultimateOverUnderTotal = 0, currentOverUnderTotal = 0;
    let variancePenultimateToCurrentOverUnder = 0, varianceBaselineToCurrentOverUnder = 0;

    // Total Current Budget
    const calculateTotalBudget = (budgetData) => {
        const { current_contract_summary: current } = budgetData;
        const currentBudgetItems = Object.keys(current.contract_structure.Budget || {});
        
        const totalBudget = currentBudgetItems.reduce((total, item) => {
            const itemValue = current.contract_structure.Budget[item] || 0;
            return total + itemValue;
        }, 0);

        return totalBudget;
    };


    let totalCurrentBudget = calculateTotalBudget(budgetData)
 
    // Helper for Calculating Contract Totals
    const calculateTotalContractValues = (a, b, c) => {
        return a + b + c;
    };
  
    const currentOverUnderItems = Object.keys(current.contract_structure["Over/Under"] || {});
    const currentJTDCostsItems = Object.keys(current.contract_structure["JTD Costs"] || {});

     currentOverUnderItems.forEach((item) => {
        const baselineValue = baseline.contract_structure["Over/Under"][item] || 0;
        const penultimateValue = penultimate.contract_structure["Over/Under"][item] || 0;
        const currentValue = current.contract_structure["Over/Under"][item] || 0;

        baselineOverUnderTotal += baselineValue;
        penultimateOverUnderTotal += penultimateValue;
        currentOverUnderTotal += currentValue;

        variancePenultimateToCurrentOverUnder += calculateVariance(penultimateValue, currentValue);
        varianceBaselineToCurrentOverUnder += calculateVariance(baselineValue, currentValue);
    });

    if (isModal === false) {
        return (
            <>
             <CardContent>
             {/* <p className="bvt-table-title">Job to Date Costs</p> */}
            
         </CardContent>

         </>
        )
    } else {

    return (
        <CardContent>
{/* =========================================== */}
{/* ============= Budget Table ================ */}
{/* =========================================== */}
        <p className="bvt-table-title">Budget</p>
            <TableContainer component={Paper} style={{ maxHeight: isModal ? '100%' : '250px' }}>
                <Table className={classes.table} aria-label="bvt-budget-table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Category</StyledTableCell>
                            <StyledTableCell>Baseline</StyledTableCell>
                            <StyledTableCell>Last Month</StyledTableCell>
                            <StyledTableCell>Current Month</StyledTableCell>
                            <StyledTableCell>Month Over Month Variance</StyledTableCell>
                            <StyledTableCell>Baseline Variance</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {/* Lump Sum Budget Section */}
                        <StyledTableRow>
                            <StyledTableCell>Lump Sum Budget</StyledTableCell>
                            <StyledTableCell />
                            <StyledTableCell />
                            <StyledTableCell />
                            <StyledTableCell />
                            <StyledTableCell />
                        </StyledTableRow>
                         {/* Loop through budget items */}
                         {currentBudgetItems.map((item) => {
                            const baselineValue = baseline.contract_structure.Budget[item] || 0;
                            const penultimateValue = penultimate.contract_structure.Budget[item] || 0;
                            const currentValue = current.contract_structure.Budget[item] || 0;
                            const variancePenultimateToCurrent = calculateVariance(penultimateValue, currentValue);
                            const varianceBaselineToCurrent = calculateVariance(baselineValue, currentValue);

                            if (item === "Allowance") {
                                // Accumulate totals for Allowance
                                baselineAllowanceTotal += baselineValue;
                                penultimateAllowanceTotal += penultimateValue;
                                currentAllowanceTotal += currentValue;
                                return null;
                            }
                            
                            if (item === "Financial Markups") {
                                // Accumulate totals for Financial Markups
                                baselineMarkupsTotal += baselineValue;
                                penultimateMarkupsTotal += penultimateValue;
                                currentMarkupsTotal += currentValue;
                                return null;
                            }

                            // Accumulate totals for Lump Sum Budget
                            baselineTotal += baselineValue;
                            penultimateTotal += penultimateValue;
                            currentTotal += currentValue;
                            variancePenultimateToCurrentTotal += variancePenultimateToCurrent;
                            varianceBaselineToCurrentTotal += varianceBaselineToCurrent;

                            // Calculate Totals for Each Section
                            baselineTotalBudgetContract = calculateTotalContractValues(baselineTotal, baselineAllowanceTotal, baselineMarkupsTotal);
                            penultimateTotalBudgetContract = calculateTotalContractValues(penultimateTotal, penultimateAllowanceTotal, penultimateMarkupsTotal);
                            currentTotalBudgetContract = calculateTotalContractValues(currentTotal, currentAllowanceTotal, currentMarkupsTotal);
                                                    

                            return (
                                <StyledTableRow key={item}>
                                    <StyledTableCell className='bvt-table-lineitem'>{item}</StyledTableCell>
                                    <StyledTableCell>{formatDollar(baselineValue)}</StyledTableCell>
                                    <StyledTableCell>{formatDollar(penultimateValue)}</StyledTableCell>
                                    <StyledTableCell>{formatDollar(currentValue)}</StyledTableCell>
                                    <StyledTableCell style={{ color: item.variancePenultimateToCurrent < 0 ? 'red' : 'inherit'}}>{formatDollar(variancePenultimateToCurrent)}</StyledTableCell>
                                    <StyledTableCell style={{ color: item.varianceBaselineToCurrent < 0 ? 'red' : 'inherit'}}>{formatDollar(varianceBaselineToCurrent)}</StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                        {/* Line Item Totals -- Budget/Revenue */}
                         {/* LUMP SUM BUDGET TOTALS */}
                        <StyledTableRow className='bvt-table-total'>
                            <StyledTableCell>Lump Sum Budget Total</StyledTableCell>
                            <StyledTableCell style={{ color: lumpSumTotals.baseline < 0 ? 'red' : 'inherit' }}>{formatDollar(lumpSumTotals.baseline)}</StyledTableCell>
                            <StyledTableCell style={{ color: lumpSumTotals.penultimate < 0 ? 'red' : 'inherit' }}>{formatDollar(lumpSumTotals.penultimate)}</StyledTableCell>
                            <StyledTableCell style={{ color: lumpSumTotals.current < 0 ? 'red' : 'inherit' }}>{formatDollar(lumpSumTotals.current)}</StyledTableCell>
                            <StyledTableCell style={{ color: variancePenultimateToCurrentLumpSum < 0 ? 'red' : 'inherit' }}>{formatDollar(variancePenultimateToCurrentLumpSum)}</StyledTableCell>
                            <StyledTableCell style={{ color: varianceBaselineToCurrentLumpSum < 0 ? 'red' : 'inherit' }}>{formatDollar(varianceBaselineToCurrentLumpSum)}</StyledTableCell>
                        </StyledTableRow>

                        {/* GMP BUDGET TOTALS */}
                        <StyledTableRow className="bvt-table-total-b">
                            <StyledTableCell>GMP Budget Total</StyledTableCell>
                            <StyledTableCell style={{ color: gmpTotals.baseline < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(gmpTotals.baseline)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: gmpTotals.penultimate < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(gmpTotals.penultimate)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: gmpTotals.current < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(gmpTotals.current)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: variancePenultimateToCurrentGMP < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(variancePenultimateToCurrentGMP)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: varianceBaselineToCurrentGMP < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(varianceBaselineToCurrentGMP)}
                            </StyledTableCell>
                        </StyledTableRow>


                        {/* Totals for Allowance Budget */}
                        <StyledTableRow>
                            <StyledTableCell>Allowance Budget</StyledTableCell>
                            <StyledTableCell style={{ color: baselineAllowanceTotal < 0 ? 'red' : 'inherit' }}>{formatDollar(baselineAllowanceTotal)}</StyledTableCell>
                            <StyledTableCell style={{ color: penultimateAllowanceTotal < 0 ? 'red' : 'inherit' }}>{formatDollar(penultimateAllowanceTotal)}</StyledTableCell>
                            <StyledTableCell style={{ color: currentAllowanceTotal < 0 ? 'red' : 'inherit' }}>{formatDollar(currentAllowanceTotal)}</StyledTableCell>
                            <StyledTableCell style={{ color: calculateVariance(penultimateAllowanceTotal, currentAllowanceTotal) < 0 ? 'red' : 'inherit' }}>{formatDollar(calculateVariance(penultimateAllowanceTotal, currentAllowanceTotal))}</StyledTableCell>
                            <StyledTableCell style={{ color: calculateVariance(baselineAllowanceTotal, currentAllowanceTotal) < 0 ? 'red' : 'inherit' }}>{formatDollar(calculateVariance(baselineAllowanceTotal, currentAllowanceTotal))}</StyledTableCell>
                        </StyledTableRow>

                        {/* Totals for Financial Markups Budget */}
                        <StyledTableRow>
                            <StyledTableCell>Financial Markups Budget</StyledTableCell>
                            <StyledTableCell>{formatDollar(baselineMarkupsTotal)}</StyledTableCell>
                            <StyledTableCell>{formatDollar(penultimateMarkupsTotal)}</StyledTableCell>
                            <StyledTableCell>{formatDollar(currentMarkupsTotal)}</StyledTableCell>
                            <StyledTableCell style={{ color: calculateVariance(penultimateMarkupsTotal, currentMarkupsTotal) < 0 ? 'red' : 'inherit' }}>{formatDollar(calculateVariance(penultimateMarkupsTotal, currentMarkupsTotal))}</StyledTableCell>
                            <StyledTableCell style={{ color: calculateVariance(baselineMarkupsTotal, currentMarkupsTotal) < 0 ? 'red' : 'inherit' }}>{formatDollar(calculateVariance(baselineMarkupsTotal, currentMarkupsTotal))}</StyledTableCell>
                        </StyledTableRow>

                        {/* Total Budget Contract Values */}
                        <StyledTableRow className="bvt-table-total">
                            <StyledTableCell>Total Budget Contract Values</StyledTableCell>
                            <StyledTableCell>{formatDollar(baselineTotalBudgetContract)}</StyledTableCell>
                            <StyledTableCell>{formatDollar(penultimateTotalBudgetContract)}</StyledTableCell>
                            <StyledTableCell>{formatDollar(currentTotalBudgetContract)}</StyledTableCell>
                            <StyledTableCell style={{ color: calculateVariance(penultimateTotalBudgetContract, currentTotalBudgetContract) < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(calculateVariance(penultimateTotalBudgetContract, currentTotalBudgetContract))}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: calculateVariance(baselineTotalBudgetContract, currentTotalBudgetContract) < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(calculateVariance(baselineTotalBudgetContract, currentTotalBudgetContract))}
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

{/* ====================================================================== */}
{/* ========================= Costs BVT Table ============================ */}
{/* ====================================================================== */}
        <p className="bvt-table-title">Costs</p>
            <TableContainer component={Paper} style={{ maxHeight: isModal ? '100%' : '250px' }}>
                <Table className={classes.table} aria-label="bvt-costs-table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Category</StyledTableCell>
                            <StyledTableCell>Baseline</StyledTableCell>
                            <StyledTableCell>Last Month</StyledTableCell>
                            <StyledTableCell>Current Month</StyledTableCell>
                            <StyledTableCell>Month Over Month Variance</StyledTableCell>
                            <StyledTableCell>Baseline Variance</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {/* Lump Sum Costs  */}
                        <StyledTableRow>
                            <StyledTableCell>Lump Sum Costs</StyledTableCell>
                            <StyledTableCell />
                            <StyledTableCell />
                            <StyledTableCell />
                            <StyledTableCell />
                            <StyledTableCell />
                        </StyledTableRow>
                        {/* Loop through budget items */}
                        {currentCostItems.map((item) => {
                            const baselineValue = baseline.contract_structure.Cost[item] || 0;
                            const penultimateValue = penultimate.contract_structure.Cost[item] || 0;
                            const currentValue = current.contract_structure.Cost[item] || 0;
                            const variancePenultimateToCurrent = calculateVariance(penultimateValue, currentValue);
                            const varianceBaselineToCurrent = calculateVariance(baselineValue, currentValue);

                            if (item === "Allowance") {
                                // Accumulate totals for Allowance
                                baselineCostAllowanceTotal += baselineValue;
                                penultimateCostAllowanceTotal += penultimateValue;
                                currentCostAllowanceTotal += currentValue;
                                return null;
                            }
                            
                            if (item === "Financial Markups") {
                                // Accumulate totals for Financial Markups
                                baselineCostMarkupsTotal += baselineValue;
                                penultimateCostMarkupsTotal += penultimateValue;
                                currentCostMarkupsTotal += currentValue;
                                return null;
                            }

                            // Accumulate totals for Lump Sum Budget
                            baselineCostTotal += baselineValue;
                            penultimateCostTotal += penultimateValue;
                            currentCostTotal += currentValue;
                            variancePenultimateToCurrentTotalCost += variancePenultimateToCurrent;
                            varianceBaselineToCurrentTotalCost += varianceBaselineToCurrent;

                            // Calculate Totals for Each Section
                            baselineTotalCostContract = calculateTotalContractValues(baselineCostTotal, baselineCostAllowanceTotal, baselineCostMarkupsTotal);
                            penultimateTotalCostContract = calculateTotalContractValues(penultimateCostTotal, penultimateCostAllowanceTotal, penultimateCostMarkupsTotal);
                            currentTotalCostContract = calculateTotalContractValues(currentCostTotal, currentCostAllowanceTotal, currentCostMarkupsTotal);
                                                    

                            return (
                                <StyledTableRow key={item}>
                                    <StyledTableCell className="bvt-table-lineitem">{item}</StyledTableCell>
                                    <StyledTableCell style={{ color: baselineValue < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(baselineValue)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: penultimateValue < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(penultimateValue)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: currentValue < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(currentValue)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: variancePenultimateToCurrentTotalCost < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(variancePenultimateToCurrentTotalCost)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: varianceBaselineToCurrentTotalCost < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(varianceBaselineToCurrentTotalCost)}
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                        {/* Line Item Totals -- Costs */}
                        <StyledTableRow className="bvt-table-total">
                        <StyledTableCell>Lump Sum Costs Total</StyledTableCell>
                            <StyledTableCell style={{ color: lumpSumCosts.baseline < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(lumpSumCosts.baseline)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: lumpSumCosts.penultimate < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(lumpSumCosts.penultimate)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: lumpSumCosts.current < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(lumpSumCosts.current)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: variancePenultimateToCurrentLumpSumCosts < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(variancePenultimateToCurrentLumpSumCosts)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: varianceBaselineToCurrentLumpSumCosts < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(varianceBaselineToCurrentLumpSumCosts)}
                            </StyledTableCell>
                        </StyledTableRow>

                       {/* GMP Costs Total */}
                        <StyledTableRow className="bvt-table-total-b">
                            <StyledTableCell>GMP Costs Total</StyledTableCell>
                            <StyledTableCell style={{ color: gmpCosts.baseline < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(gmpCosts.baseline)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: gmpCosts.penultimate < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(gmpCosts.penultimate)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: gmpCosts.current < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(gmpCosts.current)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: variancePenultimateToCurrentGMPCosts < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(variancePenultimateToCurrentGMPCosts)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: varianceBaselineToCurrentGMPCosts < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(varianceBaselineToCurrentGMPCosts)}
                            </StyledTableCell>
                        </StyledTableRow>

                        {/* Allowance Costs */}
                        <StyledTableRow>
                            <StyledTableCell>Allowance Costs</StyledTableCell>
                            <StyledTableCell style={{ color: (baseline.contract_structure.Cost['Allowance'] || 0) < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(baseline.contract_structure.Cost['Allowance'] || 0)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: (penultimate.contract_structure.Cost['Allowance'] || 0) < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(penultimate.contract_structure.Cost['Allowance'] || 0)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: (current.contract_structure.Cost['Allowance'] || 0) < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(current.contract_structure.Cost['Allowance'] || 0)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: calculateVariance(penultimateCostAllowanceTotal, currentCostAllowanceTotal) < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(calculateVariance(penultimateCostAllowanceTotal, currentCostAllowanceTotal))}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: calculateVariance(baselineCostAllowanceTotal, currentCostAllowanceTotal) < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(calculateVariance(baselineCostAllowanceTotal, currentCostAllowanceTotal))}
                            </StyledTableCell>
                        </StyledTableRow>

                        {/* Financial Markups Costs */}
                        <StyledTableRow>
                            <StyledTableCell>Financial Markups Costs</StyledTableCell>
                            <StyledTableCell style={{ color: (baseline.contract_structure.Cost['Financial Markups'] || 0) < 0 ? 'red' : 'inherit' }}>
                            {formatDollar(baseline.contract_structure.Cost['Financial Markups'] || 0)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: (penultimate.contract_structure.Cost['Financial Markups'] || 0) < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(penultimate.contract_structure.Cost['Financial Markups'] || 0)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: (current.contract_structure.Cost['Financial Markups'] || 0) < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(current.contract_structure.Cost['Financial Markups'] || 0)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: calculateVariance(penultimateCostMarkupsTotal, currentCostMarkupsTotal) < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(calculateVariance(penultimateCostMarkupsTotal, currentCostMarkupsTotal))}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: calculateVariance(baselineCostMarkupsTotal, currentCostMarkupsTotal) < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(calculateVariance(baselineCostMarkupsTotal, currentCostMarkupsTotal))}
                            </StyledTableCell>
                        </StyledTableRow>

                        {/* Total Cost Contract Values */}
                        <StyledTableRow className="bvt-table-total">
                            <StyledTableCell>Total Budget Contract Values</StyledTableCell>
                            <StyledTableCell style={{ color: baselineTotalCostContract < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(baselineTotalCostContract)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: penultimateTotalCostContract < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(penultimateTotalCostContract)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: currentTotalCostContract < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(currentTotalCostContract)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: calculateVariance(penultimateTotalCostContract, currentTotalCostContract) < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(calculateVariance(penultimateTotalCostContract, currentTotalCostContract))}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: calculateVariance(baselineTotalCostContract, currentTotalCostContract) < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(calculateVariance(baselineTotalCostContract, currentTotalCostContract))}
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

{/* ============================================================ */}
{/* ============= Total Busts/Pickups BVT Table ================ */}
{/* ============================================================ */}
            <p className="bvt-table-title">Busts/Pickups</p>
            <TableContainer component={Paper} style={{ maxHeight: isModal ? '100%' : '250px' }}>
                <Table className={classes.table} aria-label="bvt-busts-pickups-table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Category</StyledTableCell>
                            <StyledTableCell>Baseline</StyledTableCell>
                            <StyledTableCell>Last Month</StyledTableCell>
                            <StyledTableCell>Current Month</StyledTableCell>
                            <StyledTableCell>Month Over Month Variance</StyledTableCell>
                            <StyledTableCell>Baseline Variance</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {currentOverUnderItems.map((item) => {
                            const baselineValue = baseline.contract_structure["Over/Under"][item] || 0;
                            const penultimateValue = penultimate.contract_structure["Over/Under"][item] || 0;
                            const currentValue = current.contract_structure["Over/Under"][item] || 0;
                            const monthOverMonthVariance = calculateVariance(penultimateValue, currentValue) || 0;
                            const baselineVariance = calculateVariance(baselineValue, currentValue) || 0;

                            return (
                                <StyledTableRow key={item}>
                                    <StyledTableCell>{item}</StyledTableCell>
                                    <StyledTableCell style={{ color: baselineValue < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(baselineValue)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: penultimateValue < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(penultimateValue)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: currentValue < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(currentValue)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: monthOverMonthVariance < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(monthOverMonthVariance)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: baselineVariance < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(baselineVariance)}
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}

                        {/* Total Busts/Pickups */}
                        <StyledTableRow className="bvt-table-total">
                            <StyledTableCell>Lump Sum Busts/Pickups Total</StyledTableCell>
                            <StyledTableCell style={{ color: baselineOverUnderTotal < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(baselineOverUnderTotal)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: penultimateOverUnderTotal < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(penultimateOverUnderTotal)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: currentOverUnderTotal < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(currentOverUnderTotal)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: variancePenultimateToCurrentOverUnder < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(variancePenultimateToCurrentOverUnder)}
                            </StyledTableCell>
                            <StyledTableCell style={{ color: varianceBaselineToCurrentOverUnder < 0 ? 'red' : 'inherit' }}>
                                {formatDollar(varianceBaselineToCurrentOverUnder)}
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <p className="bvt-table-title">Summary Level 2 (GC/GR Spend)</p>
            <TableContainer component={Paper} style={{ maxHeight: isModal ? '100%' : '250px' }}>
                 <Table className={classes.table} aria-label="jtd-costs-table">
                     <TableHead>
                         <StyledTableRow>
                             <StyledTableCell>Category</StyledTableCell>
                             <StyledTableCell>Baseline</StyledTableCell>
                             <StyledTableCell>Last Month</StyledTableCell>
                             <StyledTableCell>Current Month</StyledTableCell>
                             <StyledTableCell>Month Over Month Variance</StyledTableCell>
                             <StyledTableCell>Total Bust/Pickup</StyledTableCell>
                         </StyledTableRow>
                     </TableHead>
                     <TableBody>
                         {currentJTDCostsItems
                         .filter((item) => item === "LS-GC/GR")
                         .map((item) => {
                             const baselineValueJTD = baseline.contract_structure["JTD Costs"][item] || 0;
                             const penultimateValueJTD = penultimate.contract_structure["JTD Costs"][item] || 0;
                             const currentValueJTD = current.contract_structure["JTD Costs"][item] || 0;
                             const monthOverMonthVarianceJTD = calculateVariance(penultimateValueJTD, currentValueJTD);
                             const totalBustPickupJTD = calculateVariance(baselineValueJTD, currentValueJTD);
 
                             return (
                                 <StyledTableRow key={item}>
                                    <StyledTableCell>{item}</StyledTableCell>
                                    <StyledTableCell style={{ color: baselineValueJTD < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(baselineValueJTD)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: penultimateValueJTD < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(penultimateValueJTD)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: currentValueJTD < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(currentValueJTD)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: monthOverMonthVarianceJTD < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(monthOverMonthVarianceJTD)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: totalBustPickupJTD < 0 ? 'red' : 'inherit' }}>
                                        {formatDollar(totalBustPickupJTD)}
                                    </StyledTableCell>
                                 </StyledTableRow>
                             );
                         })}
                     </TableBody>
                 </Table>
             </TableContainer>

             <p className="bvt-table-title">Summary Level 3 (Owner Change Order Analysis)</p>
           <TableContainer component={Paper} style={{ maxHeight: isModal ? '100%' : '250px' }}>
                <Table className={classes.table} aria-label="change-events-revenue-table">
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell>Category</StyledTableCell>
                        <StyledTableCell>Total Overall ($)</StyledTableCell>
                        <StyledTableCell>% of Budget</StyledTableCell>
                        <StyledTableCell>Monthly Variance</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {/* Change Events Revenue */}
                    <StyledTableRow>
                        <TableCell>Change Events Revenue</TableCell>
                        <TableCell style={{ color: current.change_event_revenue < 0 ? 'red' : 'inherit' }}>
                            {formatDollar(current.change_event_revenue)}
                        </TableCell>
                        <TableCell>{formatPercentage((current.change_event_revenue / totalCurrentBudget) * 100)}</TableCell>
                        <TableCell style={{ color: (current.change_event_revenue - penultimate.change_event_revenue) < 0 ? 'red' : 'inherit' }}>
                            {formatDollar(current.change_event_revenue - penultimate.change_event_revenue)}
                        </TableCell>
                    </StyledTableRow>
                    {/* Open PCOs */}
                    <StyledTableRow>
                        <TableCell>Open PCOs</TableCell>
                        <TableCell style={{ color: current.open_pcos < 0 ? 'red' : 'inherit' }}>
                            {formatDollar(current.open_pcos)}
                        </TableCell>
                        <TableCell>{formatPercentage((current.open_pcos / totalCurrentBudget) * 100)}</TableCell>
                        <TableCell style={{ color: (current.open_pcos - penultimate.open_pcos) < 0 ? 'red' : 'inherit' }}>
                            {formatDollar(current.open_pcos - penultimate.open_pcos)}
                        </TableCell>
                    </StyledTableRow>
                    
                    {/* Approved PCOs & PCCOs */}
                    <StyledTableRow>
                        <TableCell>Approved PCOs & PCCOs</TableCell>
                        <TableCell style={{ color: current.approved_pcos_pccos < 0 ? 'red' : 'inherit' }}>
                            {formatDollar(current.approved_pcos_pccos)}
                        </TableCell>
                        <TableCell>{formatPercentage((current.approved_pcos_pccos / totalCurrentBudget) * 100)}</TableCell>
                        <TableCell style={{ color: (current.approved_pcos_pccos - penultimate.approved_pcos_pccos) < 0 ? 'red' : 'inherit' }}>
                            {formatDollar(current.approved_pcos_pccos - penultimate.approved_pcos_pccos)}
                        </TableCell>
                    </StyledTableRow>

                    {/* Totals */}
                    <StyledTableRow className="bvt-table-total" style={{backgroundColor: 'white'}}>
                        <TableCell>Total</TableCell>
                        <TableCell style={{ color: (current.change_event_revenue + current.open_pcos + current.approved_pcos_pccos) < 0 ? 'red' : 'inherit' }}>
                            {formatDollar(current.change_event_revenue + current.open_pcos + current.approved_pcos_pccos)}
                        </TableCell>
                        <TableCell>{formatPercentage(((current.change_event_revenue + current.open_pcos + current.approved_pcos_pccos) / totalCurrentBudget) * 100)}</TableCell>
                        <TableCell style={{ color: ((current.change_event_revenue + current.open_pcos + current.approved_pcos_pccos) - (penultimate.open_pcos + penultimate.approved_pcos_pccos + penultimate.change_event_revenue)) < 0 ? 'red' : 'inherit' }}>
                            {formatDollar((current.change_event_revenue + current.open_pcos + current.approved_pcos_pccos) - (penultimate.open_pcos + penultimate.approved_pcos_pccos + penultimate.change_event_revenue))}
                        </TableCell>
                    </StyledTableRow>
                </TableBody>
                </Table>
            </TableContainer>
        </CardContent>
    );
    };
};

export default BVTTablesComponent;