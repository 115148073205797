import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  TextField,
  Grid,
} from "@mui/material";

const BVTSettingsFields = ({ initialValues, onFieldsChange }) => {
  const [fields, setFields] = useState({
    contract_structure: "GMP",
    job_has_deferred_tax: false,
    neg_insurance_percent: "",
    neg_fee_percent: "",
    shared_savings: false,
    shared_savings_split_percent_to_arco: "",
    max_shared_savings_amt: "",
  });

  // Check and repopulate fields when value changes
  useEffect(() => {
    if (initialValues) {
      setFields((prev) => ({ ...prev, ...initialValues }));
    }
  }, [initialValues]);

  const handleFieldChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    const updatedFields = {
      ...fields,
      [name]: newValue,
    };

    setFields(updatedFields);

    // Notify parent BVT Settings Fields Component
    if (onFieldsChange) {
      onFieldsChange(updatedFields);
    }
  };

  // List of fields to disable for when Lump Sum is selected
  const blockedFields = [
    "job_has_deferred_tax",
    "neg_insurance_percent",
    "neg_fee_percent",
    "shared_savings",
    "shared_savings_split_percent_to_arco",
    "max_shared_savings_amt",
  ];

  const isFieldDisabled = (fieldName) => {
    return (
      fields.contract_structure === "Lump Sum" &&
      blockedFields.includes(fieldName)
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        marginTop: "20px",
        marginBottom: "20px",
        marginLeft: '20px',
        marginRight: '20px',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {/* Contract Structure Dropdown */}
      <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Contract Structure</InputLabel>
        <Select
          name="contract_structure"
          value={fields.contract_structure || "Missing"}
          onChange={handleFieldChange}
          label="Contract Structure" 
        >
          <MenuItem value="GMP">GMP</MenuItem>
          <MenuItem value="Lump Sum">Lump Sum</MenuItem>
        </Select>
      </FormControl>
      </Grid>

      {/* Two-Column Layout for Fields */}
      
        <Grid item xs={12} md={6}>
        <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={fields.job_has_deferred_tax || false}
                onChange={handleFieldChange}
                name="job_has_deferred_tax"
                color="primary"
                disabled={isFieldDisabled("job_has_deferred_tax")}
              />
            }
            label="Job Has Deferred Tax"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={fields.shared_savings || false}
                onChange={handleFieldChange}
                name="shared_savings"
                color="primary"
                disabled={isFieldDisabled("shared_savings")}
              />
            }
            label="Shared Savings"
          />
          </span>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Negative Insurance Percent"
            variant="outlined"
            name="neg_insurance_percent"
            value={fields.neg_insurance_percent || ""}
            onChange={handleFieldChange}
            type="number"
            InputProps={{ inputProps: { min: -100, max: 100 } }}
            disabled={isFieldDisabled("neg_insurance_percent")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Negative Fee Percent"
            variant="outlined"
            name="neg_fee_percent"
            value={fields.neg_fee_percent || ""}
            onChange={handleFieldChange}
            type="number"
            InputProps={{ inputProps: { min: -100, max: 100 } }}
            disabled={isFieldDisabled("neg_fee_percent")}
            fullWidth          
            />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Shared Savings Split Percent to ARCO"
            variant="outlined"
            name="shared_savings_split_percent_to_arco"
            value={fields.shared_savings_split_percent_to_arco || ""}
            onChange={handleFieldChange}
            type="number"
            InputProps={{ inputProps: { min: 0, max: 100 } }}
            disabled={isFieldDisabled("shared_savings_split_percent_to_arco")}
            fullWidth          
            />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Max Shared Savings Amount"
            variant="outlined"
            name="max_shared_savings_amt"
            value={fields.max_shared_savings_amt || ""}
            onChange={handleFieldChange}
            type="number"
            disabled={isFieldDisabled("max_shared_savings_amt")}
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BVTSettingsFields;
