import React, {useState, useEffect, useRef} from 'react';
import amiLogo from '../static/amiLogo.png';
import '../../project_dashboard/static/project-dashboard-styles.css';
import IconInfo from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@mui/icons-material/Email';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import Lottie from 'lottie-react';
import loadingAnimation from '../static/loadingAnimationLottie.json';
import concurLogo from '../static/concur_logo.svg'
import bvtLogo from '../static/bvt_logo_revised2.svg'

import { Grid, Card, CardContent, Typography, useMediaQuery, Link, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, DialogTitle, Button } from '@mui/material';
import { Container } from 'react-bootstrap';


// COMPONENT IMPORTS
import HeaderComponent from '../Components/HeaderComponent';
import ProjectTeamTable from '../Components/ProjectTeamComponent';
import ProjectStageCard from '../Components/ProjectStatusComponent';
import TotalOwnerContractValue from '../Components/OwnerContractSummaryComponent';
import StartDateCard from '../Components/StartDateComponent';
import TCODateCard from '../Components/TCODateComponent';
import ProjectedEndDateCard from '../Components/ProjectedEndDate';
import ProjectTypeCard from '../Components/ProjectTypeComponent';
import SquareFootageCard from '../Components/SquareFootageComponent';
import PriceSFCard from '../Components/PricePerSFComponent';
import ProjectGrade from '../Components/ProjectGradeComponent';
import PercentComplete from '../Components/PercentCompleteComponent';
import ProgressTrackerTable from '../Components/ProgressTrackerComponent';
import OverUnderSummaryChart from '../Components/OverUnderComponent';
import PCOTypeSummary from '../Components/PCOTypeSummaryComponent';
import OwnerBillingsTable from '../Components/OwnerBillingsTableComponent';
import ProjectExternalLinks from '../Components/ProjectLinksComponent';
import CFASplit from '../Components/CFASplitComponent';
import KeyInfoTable from '../Components/KeyProjectInformationComponent';
import ProjectLocationMap from '../Components/ProjectLocationComponent';
import InfoIconButton from '../Components/InfoIconButtonComponent';
import TablePopoutButton from '../Components/TablePopoutIconComponent';
import BudgetSnapshotSelector from '../Components/BudgetSnapshotSelectorComponent';
import ProjectSettingsModal from '../Components/ProjectSettingsContainerModal';
import ContractStrutureChart from '../Components/ContractStructureComponent';
import ConcurExpenseTracker from '../Components/ConcurExpenseTracker';
import ProfitPercentage from '../Components/CurrentProfitComponent';
import MasterProjectInfoCard from '../Components/MasterProjectInformationCard';
import MasterContractInfoCard from '../Components/MasterContractInformationCard';
import PercentCompleteBars from '../Components/PercentageCompleteBarsComponent';
import { format } from 'date-fns';
import ProfitDataChart from '../Components/ProfitChartComponent';
import FooterComponent from '../Components/FooterComponent';
import ARCOContingencyChart from '../Components/ArcoContingencyComponent';
import CostsVsBillingsChart from '../Components/CostsAndBillingsComponent';
import { initial } from 'lodash';
import DivisionSelect from '../Components/DivisionSelectorComponent';
import PortfolioProjectTeamTable from '../Components/ProjectTeamComponentForPortfolio';
import PortfolioMasterProjectInfoCard from '../Components/MasterProjectInformation-PortfolioComponent';
import FinancialSummaryComponent from '../Components/PortfolioCardFinancialSummaryComponent';
import BVTHomeComponent from '../Components/BvtHomeComponent';
import BVTTablesComponent from '../Components/BVTTablesComponent';

// Establish Today's Date and Date Options
const date_options = {
    weeday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}
const today_date = new Date().toLocaleDateString("en-US", date_options);

// Email All Button Styling
const useStyles = makeStyles((theme) => ({
    emailAllButton: {
      marginLeft: theme.spacing(1),
      backgroundColor: '#007bff',
      maxHeight: '30px'
    },
  }));

  
const ProjectDashboard = ({
    projects,
    companies,
    csrfToken,
    HUB_API_DOMAIN_NAME,
    apiKey,
    apiHeader,
    gmapsAPI,
    portfolioView,
    setPortfolioView,
    divisionOptions,
    selectedDivisions,
    setSelectedDivisions,
    stageOptions,
    selectedStages,
    setSelectedStages, 
    opsLeaderOptions,
    selectedOpsLeaders,
    setSelectedOpsLeaders,
    projectTypeOptions,
    selectedProjectTypes,
    setSelectedProjectTypes,
    accountOptions,
    selectedAccounts,
    setSelectedAccounts,
    onCompanySelect,
    selectedCompany,
    setSelectedCompany,
    handlePortfolioSearch,
    isLoading,

}) => {
    const classes = useStyles();
    // const [selectedCompany, setSelectedCompany] = useState(null);
    const [searchQuery, setSearchQuery] = useState(""); 
    const [filteredProjects, setFilteredProjects] = useState(projects);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedProjectId, setSelectedProjectId] = useState(null)
    const [projectData, setProjectData] = useState({});
    const [contractBoxShadowColor, setContractBoxShadowColor] = useState('rgba(0, 0, 0, 0.10)');
    const [stageBoxShadowColor, setStageBoxShadowColor] = useState('rgba(0, 0, 0, 0.10)');
    const [budgetData, setBudgetData] = useState([])
    const [projectSnapshots, setProjectSnapshots] = useState({})
    const [sourceProcore, setSourceProcore] = useState('procore')
    const [selectedSnapshots, setSelectedSnapshots] = useState([]);
    const [changedSnapshots, setChangedSnapshots] = useState(null);
    const [initialSnapshots, setInitialSnapshots] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [settingsData, setSettingsData] = useState([]);
    const [currentView, setCurrentView] = useState('project');

    // State Reset When Switching Between Portfolio and Project Views --- To be replaced with Redux
    const resetFiltersOnViewChange = () => {
        setSelectedCompany(null);
        setSelectedProject(null);
        setSelectedProjectId(null);
        setProjectData({});
        setBudgetData([]);
        setSelectedSnapshots([]);
        setInitialSnapshots([]);
        setInvoiceData([]);
        setSettingsData([]);
        setSearchQuery("");
        setFilteredProjects([]);
        setShowAllProfitData(false);
        setShowAllContData(false);
        setSelectedDivisions([]);
        setSelectedStages([]);
        setSelectedOpsLeaders([]);
        setSelectedProjectTypes([]);
        setSelectedAccounts([]);
    };

    // Call Function when we switch from projectView to portfolioView
    useEffect(() => {
        if (portfolioView) {
            resetFiltersOnViewChange();
        }
    }, [portfolioView]);

    // Refs for image button logic
    const bvtTableIconPopout = useRef(null);
    const concurTableIconPopout = useRef(null);

    const handleBVTLogoClick = () => {
        if (bvtTableIconPopout.current) {
            bvtTableIconPopout.current.triggerClick();
        }
    };

    const handleConcurLogoClick = () => {
        if (concurTableIconPopout.current) {
            concurTableIconPopout.current.triggerClick();
        }
    };

    // Logic to Control how split components (6/6) are laid out when screen is shrunk
    const isWideScreen = useMediaQuery('(min-width:1900px)');
    const customGripProps = isWideScreen
        ? {xs: 6}
        : {xs: 12}


    // Handle Project View Switch
    const handleViewProjectDetails = (project) => {
        setPortfolioView(false);
        setSelectedProject(project);
        setSelectedProjectId(project.id);
      
        const company = companies.find(c => c.company === project.company);
        setSelectedCompany(company);
        if (company) {
          fetchProjectsForCompany(company.company);
        }
      };

    // Mass Email Functions
    const getAllEmails = (roles) => {
        const emails = roles
          .filter(role => role.email)
          .map(role => role.email.toLowerCase())
          .filter((email, index, self) => self.indexOf(email) === index);
        return emails;
      };
    
      // Email All Handler
      const handleEmailAll = (emails, projectName) => {
        if (emails.length === 0) {
          alert("No team members have email addresses.");
          return;
        }
        const subject = encodeURIComponent(`Quick Question on ${selectedProject.project_number} ${selectedProject.name}`);
        const mailtoLink = `mailto:${emails.join(',')}`
          + `?subject=${subject}`
        window.location.href = mailtoLink;
      };
    
      // Extract all emails from budgetData
      const allEmails = getAllEmails(budgetData?.project_team || []);

    // Graph Logic
    const [showAllContData, setShowAllContData] = useState(false);
    const [showAllProfitData, setShowAllProfitData] = useState(false);

    const [moreThanSixMonthsData, setMoreThanSixMonthsData] = useState(false)


    useEffect(() => {
        if (!budgetData || !budgetData.profit_data || budgetData.profit_data.length === 0) return false;

        const earliestDate = new Date(budgetData.profit_data[0].snapshot_date);
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

        if (earliestDate < sixMonthsAgo) {
            setMoreThanSixMonthsData(true)
        } else {
            setMoreThanSixMonthsData(false)
        }
    }, [selectedProjectId, selectedProject])

// Checking for full data coming from API 
      const isProjectDataComplete = (project) => {
        return (
          project.project_number &&
          project.account_name &&
          project.baseline_contract_summary
        );
      };

    // Handle company selection change -- Project View
    const handleCompanySelectChangeForProjectView = (event, newValue) => {
        setSelectedCompany(newValue);

        onCompanySelect(newValue);
    
        // If company selected, fetch its projects
        if (newValue) {
            fetchProjectsForCompany(newValue.company);
        } else {
            // If no company, show all projects
            setFilteredProjects(projects);
        }
    };
    // Handle selection change -- Portfolio View
    const handleCompanySelectChangeForPortfolioView = (event, newValue) => {
        setSelectedCompany(newValue);
    };

    // Reset filteredProjects when dashboard mounts
    useEffect(() => {
        if (!selectedCompany && !searchQuery) {
            setFilteredProjects(projects);
        }
    }, [projects, selectedCompany, searchQuery]);

    // Reset all filters when we switch between Views
    const resetFilters = () => {
        setSelectedCompany(null);
        setSelectedProject(null);
        setSelectedProjectId(null);
        setSearchQuery("");
        setFilteredProjects(projects);
        setSelectedSnapshots([]);
        setInitialSnapshots([]);
        setInvoiceData([]);
        setSelectedDivisions([]);
        setSelectedStages([]);
        setSelectedOpsLeaders([]);
        setSelectedProjectTypes([]);
        setSelectedAccounts([]);
       
        // FYI: If you're hitting a weird error upon switch, make sure what's feeding your dropdown AutoCompletes is added here <------
      };
    useEffect(() => {
        if (portfolioView) {
            resetFilters();
        }
    }, [portfolioView]);
    

    // Handle search query change
    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        // Filtering projects based off of search query (selection)
        const filtered = projects.filter(project => {
            const matchesSearch = project.name.toLowerCase().includes(query);
            const matchesCompany = selectedCompany
                ? project.company === selectedCompany.company
                : true; 
            return matchesSearch && matchesCompany;
        });

        setFilteredProjects(filtered);
    };

    // Handle project selection
    const handleProjectSelectChange = (selectedProjectName) => {
        const project = filteredProjects.find(proj => proj.name === selectedProjectName);
        setSelectedProject(project);
        setSelectedProjectId(project ? project.id : null);
    };

// Fetching Project based on Company Selection
const fetchProjectsForCompany = (selectedCompanyName) => {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
            'Auth': apiHeader,
            'api-key': apiKey,
        });

        fetch(`${HUB_API_DOMAIN_NAME}/phd/get_projects_and_companies/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                company_filter: selectedCompanyName,
                csrf_token: localStorage.getItem('hub_csrf_token')
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
            if (data) {
                setFilteredProjects(data.projects || []);
            } else {
                console.error("Failed to fetch projects from HUB API: ", data.message)
            }
        })
        .catch(error => {
            console.error('Error fetching projects for company:', error);
        });
    };

// Combined Fetch for grabbing all initial data triggered off of projectID change
useEffect(() => {
    if (selectedProjectId) {
      const fetchData = async () => {
        const headers = new Headers({
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
          'Auth': apiHeader,
          'api-key': apiKey,
        });
  
        try {
          const payload = {
            project_id: selectedProjectId,
            csrf_token: csrfToken,
          };
  
          // Fetch project data and settings
          const [projectResponse, settingsResponse, invoiceResponse] = await Promise.all([
            fetch(`${HUB_API_DOMAIN_NAME}/phd/get_project_data/`, {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(payload),
              credentials: 'include',
            }),
            fetch(`${HUB_API_DOMAIN_NAME}/phd/get_project_settings/`, {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(payload),
              credentials: 'include',
            }),
            fetch(`${HUB_API_DOMAIN_NAME}/phd/get_project_owner_invoice_data/`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(payload),
                credentials: 'include',
            }),
          ]);
  
          const projectData = await projectResponse.json();
          const settingsData = await settingsResponse.json();
          const invoiceData = await invoiceResponse.json();
  
          // Update project data
          if (projectData) {
            setProjectData(projectData);
          }
  
          // Update snapshots
          if (settingsData.snapshots) {
            const allSnapshots = Object.values(settingsData.snapshots).flat();
            setInitialSnapshots(allSnapshots);
  
            const initialSelectedSnapshots = allSnapshots.filter(snapshot => snapshot.selected);
            setSelectedSnapshots(initialSelectedSnapshots);
          }

          if (settingsData) {
            setSettingsData(settingsData);
          }

          // Handle Invoice Data
          if (invoiceData.data) {
            setInvoiceData(invoiceData.data);
          }

        } catch (error) {
          console.error('Error fetching project data:', error);
        }
      };
  
      // Clearing Previous Budget Data
      setBudgetData([]);
      fetchData();
    }
  }, [selectedProjectId, csrfToken, apiHeader, apiKey]);
  

useEffect(() => {
    if (selectedProjectId && !selectedProject) {
      // Fetch the selected project data
      fetchProjectData(selectedProjectId);
    }
  }, [selectedProjectId, selectedProject]);

  const fetchProjectData = (projectId) => {
    // Fetch the project data from the server
    fetch(`${HUB_API_DOMAIN_NAME}/projects/${projectId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Auth': apiHeader,
        'api-key': apiKey,
      },
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectedProject(data);
      })
      .catch((error) => {
        console.error('Error fetching project data:', error);
      });
  };

// Separate useEffect for fetching budgetData whenever selectedSnapshots or selectedProjectId changes -- still getting called multiple times, but getting there
useEffect(() => {
    if (selectedProjectId && selectedSnapshots.length > 0) {
      const fetchBudgetData = async () => {
        const headers = new Headers({
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
          'Auth': apiHeader,
          'api-key': apiKey,
        });
  
        const { actual_start_date, completion_date } = projectData.project_data || {};
        const formatted_start_date = actual_start_date ? new Date(actual_start_date).toISOString().split('T')[0] : null;
        const formatted_end_date = completion_date ? new Date(completion_date).toISOString().split('T')[0] : null;
  
        const requestBody = {
          start_date: formatted_start_date,
          end_date: formatted_end_date,
          project_id: selectedProjectId,
          selected_snapshots: selectedSnapshots.map(snapshot => snapshot.id),
          csrf_token: csrfToken,
        };
  
        try {
          const response = await fetch(`${HUB_API_DOMAIN_NAME}/phd/get_project_budget_data/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestBody),
            credentials: 'include',
          });
  
          const data = await response.json();
          if (data) {
            setBudgetData(data || []);
          } else {
            console.error('Failed to fetch project budget data from HUB API:', data.message);
          }
        } catch (e) {
          console.error('Error fetching budget data:', e);
        }
      };
  
      fetchBudgetData();
    }
  }, [selectedSnapshots, selectedProjectId, csrfToken, apiHeader, apiKey]);
  

// ========= HELPER FUNCTIONS ==============

 // Selection Handler for Selection Snapshots    
 const handleSelectionChange = (updatedSnapshots) => {
    setSelectedSnapshots(updatedSnapshots);
    setInitialSnapshots(updatedSnapshots)
};

// Track snapshot selection settings AND bvt field updates
const handleModalClose = async (selectedSnapshots, bvtFields) => {
    console.log("Attempting to push snapshots & settings update...");

    // Extract selected snapshot IDs
    const selectedIds = selectedSnapshots
        .filter(snapshot => snapshot.selected)
        .map(snapshot => snapshot.id);

    // Prepare the request body
    const requestBody = {
        project_id: selectedProjectId,
        csrf_token: csrfToken,
    };

    if (selectedIds.length > 0) {
        console.log("Found snapshots to update");
        requestBody.selected_snapshots = selectedIds;
    } else {
        console.log("No snapshots to update");
    }

    // Add BVT fields to the request body
    requestBody.other_meta_data = {
        contract_structure: bvtFields.contract_structure,
        job_has_deferred_tax: bvtFields.job_has_deferred_tax,
        neg_insurance_percent: bvtFields.neg_insurance_percent || null,
        neg_fee_percent: bvtFields.neg_fee_percent || null,
        shared_savings: bvtFields.shared_savings,
        shared_savings_split_percent_to_arco: bvtFields.shared_savings_split_percent_to_arco || null,
        max_shared_savings_amt: bvtFields.max_shared_savings_amt || null,
    };


    try {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
            'Auth': apiHeader,
            'api-key': apiKey,
        });

        const response = await fetch(`${HUB_API_DOMAIN_NAME}/phd/save_project_settings/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestBody),
            credentials: 'include',
        });

        const data = await response.json();

        if (response.ok) {
            console.log('Snapshots and settings updated successfully:', data);
        } else {
            console.error('Error updating snapshots and settings:', data.message);
        }
    } catch (error) {
        console.error('Error making POST request:', error);
    }
};

// Controls box shadow color for Owner Contract Summary component
const handleContractSummaryColorChange = (color) => {
    if (color === 'green') {
        setContractBoxShadowColor('rgba(0, 128, 0, 0.5)')
    } else if (color === 'red') {
        setContractBoxShadowColor('rgba(255, 0, 0, 0.5)')
    } else {
        setContractBoxShadowColor('rgba(0, 0, 0, 0.10)')
    }
};
const handleStageColorChange = (color) => {
    setStageBoxShadowColor(color);
};

    console.log("===== Project Dash Console Checks =====")
    console.log("Project Data")
    console.log(projectData)
    console.log("===== Budget Check =====")
    console.log("Budget Data")
    console.log(budgetData)
    console.log("===== Settings Snapshot Data =====")
    console.log(selectedSnapshots)
    console.log("+++++++++ PROJ Settings ++++++++++")
    console.log(settingsData)
    // console.log("===== INVOICE DATA =====")
    // console.log(invoiceData)
    // console.log("===== INITIAL SNAPSHOTS PD =====")
    // console.log(initialSnapshots)

// Check for missing project team members
const requiredRoles = [
    "Project Manager",
    "Superintendent",
    "Project Coordinator",
    "Project Executive",
    "General Superintendent",
    "Project Accountant"
];
let missingProjectTeam = false
let missingRoles = []

if (budgetData && Array.isArray(budgetData.project_team)) {
    for (const role of requiredRoles) {
        const roleExists = budgetData.project_team.some(member => member.role === role);
        if (!roleExists) {
            missingRoles.push(role); 
            missingProjectTeam = true;
        }
    }
}

// Set Project Procore ID for Warning Icon ToolTip Links
let procoreID = null
if (projectData) {
    procoreID = projectData?.project_data?.procore_id
}

// Calculate Average Payment Date for Payment & Invoicing History Tile

let averagePaymentAge = null

if (invoiceData && invoiceData.length > 0) {
    const calculateAveragePaymentAge = (invoiceData) => {
        if (!invoiceData || invoiceData.length === 0) {
            return 0;
        }

        const totalPaymentAge = invoiceData.reduce((acc, invoice) => acc + (invoice.pmt_age || 0), 0);
        return (totalPaymentAge / invoiceData.length).toFixed(2);
    };

    averagePaymentAge = calculateAveragePaymentAge(invoiceData)
}


    const renderProjectView = () => (
        <Grid id="main-container-proj" container spacing={1} style={{marginTop: '15px'}}>
         {/* Column 1 */}
         <Grid item xs={12} md={4}>
            <Grid container spacing={1.5} style={{ width: '100%', justifyContent: 'center' }}>
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card className="card-content-container">
                        <CardContent className="card-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Project Information Summary</Typography>
                        <div className="project-team-icon-container">
                                
                                {/* Warning Icon with Tooltip - shown when we're past subst comp but not in Warranty or Stopped Work stage */}
                                {projectData.project_data &&
                                    new Date(projectData.project_data.projected_finish_date) < new Date() &&
                                    projectData.project_data.stage !== "Warranty" &&
                                    projectData.project_data.stage !== "Stopped Work" && (
                                        <Tooltip
                                        title={
                                            <div className='warning-icon-tooltip-content'>
                                                <p>
                                                    The projected finish date has passed, and the project is not in 'Warranty' or 'Stopped Work' stage. You can update this in your Procore admin tool.
                                                </p>
                                                <Button 
                                                variant="contained" 
                                                color="primary" 
                                                size="small"
                                                onClick={() => { 
                                                    window.open(`https://app.procore.com/${procoreID}/project/admin`, '_blank');  
                                                }}
                                                style={{ marginTop: '8px', textAlign: 'center'}}
                                            >
                                                Open Procore Admin Tool
                                            </Button>
                                                </div>
                                        }
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    fontSize: '1rem',
                                                    padding: '10px',
                                                },
                                            },
                                        }}>
                                            <WarningIcon style={{ color: 'red', marginRight: '8px' }} />
                                        </Tooltip>
                                    )}

                                {/* Additional case: Warranty stage and more than 1 year from projected finish date. Should be complete */}
                                {projectData.project_data &&
                                    projectData.project_data.stage === "Warranty" &&
                                    new Date() > new Date(new Date(projectData.project_data.projected_finish_date).setFullYear(new Date(projectData.project_data.projected_finish_date).getFullYear() + 1)) && (
                                        <Tooltip
                                        title={
                                            <div className='warning-icon-tooltip-content'>
                                                <p>
                                                    The project entered its 'Warranty' phase over a year ago. Are you sure this shouldn't be marked as complete? You can update this in your Procore admin tool.
                                                </p>
                                                <Button 
                                                variant="contained" 
                                                color="primary" 
                                                size="small"
                                                onClick={() => { 
                                                    window.open(`https://app.procore.com/${procoreID}/project/admin`, '_blank');  
                                                }}
                                                style={{ marginTop: '8px', textAlign: 'center'}}
                                            >
                                                Open Procore Admin Tool
                                            </Button>
                                                </div>
                                        }
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    fontSize: '1rem',
                                                    padding: '10px',
                                                },
                                            },
                                        }}>
                                            <WarningIcon style={{ color: 'orange', marginRight: '8px', verticalAlign: 'middle' }} />
                                        </Tooltip>
                                    )}
                                     <InfoIconButton
                                    projectData={projectData}
                                    source="project-info-summary"
                                    sourceProcore={sourceProcore}
                                />
                            </div>
                    </div>

                            <MasterProjectInfoCard projectData={projectData} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                <Card className="card-content-container">
                    <CardContent className="card-content">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Project Team</Typography>
                        <div className="project-team-icon-container">
                            {/* Warning icon for missing team members */}
                            {missingProjectTeam && (
                                <Tooltip
                                    title={
                                        <div className='warning-icon-tooltip-content'>
                                            <p style={{margin: 0}}>
                                            Some project team members have missing information. Please review your Procore admin tool and update.
                                            </p>
                                        
                                        <Button 
                                        variant="contained" 
                                        color="primary" 
                                        size="small"
                                        onClick={() => { 
                                            window.open(`https://app.procore.com/${procoreID}/project/admin`, '_blank');  
                                        }}
                                        style={{ marginTop: '8px', textAlign: 'center'}}
                                    >
                                        Open Procore Admin Tool
                                    </Button>
                                </div>
                                    }
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                fontSize: '1rem',
                                                padding: '10px',
                                            },
                                        },
                                    }}
                                >
                                    <WarningIcon style={{ color: 'red', marginRight: '8px', verticalAlign: 'middle' }} />
                                </Tooltip>
                            )}
                            {/* Email All Button */}
                            {selectedProject ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<EmailIcon />}
                                    className={classes.emailAllButton}
                                    onClick={() => handleEmailAll(allEmails, selectedProject.project_number, selectedProject.name, selectedProject.arco_id)}
                                >
                                    Email All
                                </Button>
                                ) : null}
                                <InfoIconButton
                                    projectData={projectData}
                                    source="procore-team"
                                    sourceProcore={sourceProcore}
                                />
                        </div>
                    </div>
                    <ProjectTeamTable
                        selectedProject={selectedProject}
                        budgetData={budgetData}
                        reset={portfolioView}
                        />
                    </CardContent>
                </Card>
                </Grid>
                <Grid item {...customGripProps} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card className="card-content-container" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <CardContent className="card-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>CFA Split</Typography>
                                    <InfoIconButton
                                            projectData={projectData}
                                            source="salesforce"
                                        />
                            </div>
                            <div style={{ height: '100%', maxHeight:'210px' }}>
                                <CFASplit projectData={projectData} />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item {...customGripProps} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Card className="card-content-container" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <CardContent className="card-content" style={{ flex: 1 }}>
                            <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top', marginLeft: "20px" }}>Percent Complete</Typography>
                            <div style={{ height:'222px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <PercentCompleteBars projectData={projectData}
                                budgetData={budgetData} />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                <Card className="card-content-container">
                    <CardContent className="card-content">
                            <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Project Location</Typography>
                        <ProjectLocationMap
                            projectData={projectData}
                            gmapsAPI={gmapsAPI}
                        />
                    </CardContent>
                </Card>
                </Grid>
            </Grid>
            </Grid>

        {/* Column 2 */}
            <Grid item xs={12} md={4}>
            <Grid container spacing={1.5} style={{ width: '100%', justifyContent: 'center' }}>
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card className="card-content-container">
                        <CardContent className="card-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                    Contract Financial Summary
                                </Typography>
                                <InfoIconButton
                                    projectData={projectData}
                                    source="contract-financial-summary"
                                />
                            </div> 
                            <MasterContractInfoCard
                                projectData={projectData}
                                budgetData={budgetData}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className="card-content-container">
                        <CardContent className="card-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '20px' }}>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                    Budget Variance Tool (BVT)
                                </Typography>
                                <TablePopoutButton
                                    ref={bvtTableIconPopout}
                                    budgetData={budgetData}
                                    tableComponent={BVTTablesComponent}
                                    title={'Budget Variance Tool'}
                                    selectedProject={selectedProject}
                                    source="bvt-table"
                                    selectedSnapshots={selectedSnapshots}
                                />
                            </div>
                            {selectedProject && (
                                <>
                                <div style={{ textAlign: 'center', margin: '20px 0' }}>
                                <img 
                                    src={bvtLogo}
                                    alt="BVT Logo"
                                    className="table-logo-button"
                                    onClick={handleBVTLogoClick}
                                />
                                </div>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', verticalAlign: 'top' }}>Expand to view the BVT tables for this project.</Typography>
                                </>
                            )}
                            {/* <BVTTablesComponent
                                isModal={false}
                                budgetData={budgetData}
                                selectedProject={selectedProject}
                            /> */}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card className="card-content-container">
                    <CardContent className="card-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '20px' }}>
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                    Contract Structure (Work in Progress)
                                </Typography>
                                <InfoIconButton
                                    projectData={projectData}
                                    source="contract-structure"
                                />
                            </div>
                            <ContractStrutureChart
                                budgetData={budgetData}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card className="card-content-container">
                        <CardContent className="card-content" style={{ padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width:'100%', marginBottom: '16px'}}>
                                <Typography variant="subtitle1" className="card-title">
                                    Profit
                                </Typography>
                                {budgetData && budgetData.profit_data && !moreThanSixMonthsData && (
                                    <Button variant="contained" className="toggle-primary-button" 
                                        onClick={() => setShowAllProfitData(!showAllProfitData)}>
                                        {showAllProfitData ? 'Show Last 6 Months' : 'Show All Data'}
                                    </Button>
                                    )}
                            </div>
                        <div style={{ width: '100%', height: '400px' }}>
                            <ProfitDataChart 
                                budgetData={budgetData} 
                                showAllProfitData={showAllProfitData}
                            />
                        </div>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card className="card-content-container">
                        <CardContent className="card-content" style={{ padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width:'100%', marginBottom: '16px'}}>
                                <Typography variant="subtitle1" className="card-title">
                                    ARCO Contingency
                                </Typography>
                                {budgetData && budgetData.contingency_data && !moreThanSixMonthsData && (
                                    <Button
                                        variant="contained"
                                        className="toggle-primary-button"
                                        onClick={() => setShowAllContData(!showAllContData)}
                                    >
                                        {showAllContData ? 'Show Last 6 Months' : 'Show All Data'}
                                    </Button>
                                )}
                            </div>
                            <div style={{ width: '100%', height: '400px' }}>
                                <ARCOContingencyChart
                                budgetData={budgetData}
                                showAllContData={showAllContData}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} style={{display: 'none'}}>
                <Card className="card-content-container">
                <CardContent className="card-content" style={{marginLeft: "20px", marginRight: "20px"}}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>PCO Type Summary (Work in Progress)</Typography>
                    {/* <div className="banner-container">
                    <Typography className="red-datawarehouse-banner">
                        In Development
                    </Typography>
                    </div> */}
                    
                    <PCOTypeSummary 
                        budgetData={budgetData}
                    /> 
                    </CardContent>
                </Card>
                </Grid>
               
            </Grid>
            </Grid>

        {/* Column 3 */}
            <Grid item xs={12} md={4}>
            <Grid container spacing={1.5}>
            <Grid item xs={12}>
                <Card className="card-content-container">
                <CardContent className="card-content">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px"}}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top'}}>Cost vs Billings</Typography>
                    </div>
                    <CostsVsBillingsChart
                        budgetData={budgetData}
                        invoiceData={invoiceData}
                    />
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className="card-content-container">
                        <CardContent className="card-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '20px' }}>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                    Payment & Invoicing History
                                </Typography>
                                {/* <div className="red-datawarehouse-banner" style={{ position: 'relative', marginTop: '15px' }}>
                                    Awaiting VP Data
                                </div> */}
                                <TablePopoutButton
                                    budgetData={budgetData}
                                    tableComponent={OwnerBillingsTable}
                                    title={'Payment & Invoicing History'}
                                    invoiceData={invoiceData}
                                    selectedProject={selectedProject}
                                    source="payment-invoicing"
                                />
                            </div>
                            <OwnerBillingsTable 
                                isModal={false}
                                invoiceData={invoiceData}
                                selectedProject={selectedProject}
                            />
                            
                            {averagePaymentAge && (
                                <Typography className="titillium-web-base" style={{textAlign: 'center'}}>
                                    Average Payment Time: {averagePaymentAge} days
                                </Typography>
                            )}                       
                            
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className="card-content-container">
                        <CardContent className="card-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Concur Expense Tracker</Typography>
                                <TablePopoutButton 
                                    ref={concurTableIconPopout}
                                    tableComponent={ConcurExpenseTracker}
                                    concurUrl={projectData?.project_data?.concur_expense_tracker_url}
                                    title={'Concur Expense Tracker'}
                                    source="concur-table"
                                />
                            </div>
                            {selectedProject && (
                                <>
                                <div style={{ textAlign: 'center', margin: '20px 0' }}>
                                <img 
                                    src={concurLogo} 
                                    alt="Concur Expense Tracker Image" 
                                    style={{ maxWidth: '10%'}}
                                    className="table-logo-button"
                                    onClick={handleConcurLogoClick}
                                />
                                </div>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', verticalAlign: 'top' }}>Expand to view the Concur expenses charging the project.</Typography>
                                </>
                            )}
                            {/* <ProgressTrackerTable style={{maxHeight: '150px'}}
                                isModal={true}
                            /> */}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className="card-content-container">
                    <CardContent className="card-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                            <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Top Budget Pickups/Busts</Typography>
                            <TablePopoutButton 
                                budgetData={budgetData}
                                tableComponent={OverUnderSummaryChart}
                                selectedSnapshots={selectedSnapshots}
                                title={'Trade Over/Under Summary'}
                                source='trade over/under'
                                selectedProject={selectedProject}
                                apiKey={apiKey}
                                apiHeader={apiHeader}
                                csrfToken={csrfToken}
                                HUB_API_DOMAIN_NAME={HUB_API_DOMAIN_NAME}
                            />
                        </div>
                        <OverUnderSummaryChart 
                            budgetData={budgetData}
                            isModal={false}
                            selectedSnapshots={selectedSnapshots}
                            selectedProject={selectedProject}
                            apiKey={apiKey}
                            apiHeader={apiHeader}
                            csrfToken={csrfToken}
                            HUB_API_DOMAIN_NAME={HUB_API_DOMAIN_NAME}
                        />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} style={{display: 'none'}}>
                    <Card className="card-content-container">
                        <CardContent className="card-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Progress Tracker (Work in Progress)</Typography>
                            {/* <div className="red-datawarehouse-banner" style={{position: 'relative', marginTop: '15px', color: 'red'}}>
                            Work In Progress
                            </div> */}
                                <TablePopoutButton 
                                    budgetData={budgetData}
                                    tableComponent={ProgressTrackerTable}
                                    title={'Progress Tracker'}
                                />
                            </div>
                            <ProgressTrackerTable style={{maxHeight: '150px'}}
                                isModal={false}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                </Grid>
            </Grid>
      </Grid>
    );
// ============================================================
// ========= PORTFOLIO VIEW CONTAINER AND LAYOUT ==============
// ============================================================
const renderPortfolioView = () => {
    // Check if a company is selected
    if (!selectedCompany) {
        return (
          <Grid container spacing={2} style={{marginTop: '15px', height: '90vh', width: '95vw', margin: '0 auto'}}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" style={{color: 'red'}}>
                Please select a company to view projects.
              </Typography>
            </Grid>
          </Grid>
        );
      }
   // Check if loading
   if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh', 
        }}
      >
        <Grid container spacing={2} style={{marginTop: '15px', height: '90vh', width: '98vw', margin: '0 auto'}}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Fetching projects...
            </Typography>
            <Lottie
              animationData={loadingAnimation}
              loop
              autoplay
              style={{ width: 'auto', height: '50vh' }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
    
      // Check if projects have full data
      if (projects) {
      }
      if (
        !projects ||
        projects.length === 0 ||
        !isProjectDataComplete(projects[0])
      ) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh', 
                }}
                >
            <Grid container spacing={2} style={{marginTop: '15px', height: '90vh', width: '98vw', margin: '0 auto'}}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                Fetching projects...
            </Typography>
                <Lottie
                    animationData={loadingAnimation}
                    loop
                    autoplay
                    style={{ width: 'auto', height: '50vh' }}
                />
              
            </Grid>
          </Grid>
          </Box>
        );
      }

    //   Case where no projects for filters
      if (!isLoading && projects.length === 0) {
        return (
          <Grid container spacing={2} style={{marginTop: '15px', height: '90vh', width: '95vw', margin: '0 auto'}}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                No projects found for the selected filters.
              </Typography>
            </Grid>
          </Grid>
        );
      }

    //   Card Layout
    // console.log("PROJECT DATA PORTFOLIO")
    // console.log(projects)
    return (
        <Grid container spacing={2} style={{marginTop: '15px', width: '98vw', margin: '0 auto', borderRadius: '10px'}}>
            <Grid item xs={12}>
                <Typography variant="h6" align="center" className="card-title" style={{ marginBottom: '10px', color: 'black' }}>
                {`Total Projects: ${projects.length}`}
                </Typography>
            </Grid>
        {/* Map over all projects and assign to individual cards */}
        {projects.map((project) => (
            <Grid item xs={12} key={project.id}>
            <Card className="project-card">
                <CardContent className="project-card">
                {/* Card Header */}
                <div
                    className="portfolio-card-header"
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
                    }}
                >
                    <Typography variant="h6">
                    {project.project_number} | {project.name} | ARCO ID: {project.arco_id}
                    </Typography>
                    <Button
                        variant="text"
                        style={{ color: 'white' }}
                        size='small'
                        onClick={() => handleViewProjectDetails(project)}
                        >
                        View Project Details
                        <OpenInNewIcon style={{ marginLeft: '5px', fontSize: '20px' }} />
                    </Button>
                </div>

          {/* Card Components */}
          <div className="portfolio-card-information-container">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2.5}>
                <CardContent className="portfolio-component-card">
                        <PortfolioProjectTeamTable
                        projectNumber={project.project_number}
                        arcoID={project.arco_id}
                        projectName={project.name}
                        roles={project.project_team || []}
                        />
                    </CardContent>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CardContent className="portfolio-component-card">
                    <PortfolioMasterProjectInfoCard projectData={project} />
                </CardContent>
              </Grid>
              <Grid item xs={12} sm={3}>
                <CardContent className="portfolio-component-card">
                    <FinancialSummaryComponent 
                        projectData={project}
                    />
                    </CardContent>
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <CardContent className="portfolio-component-card">
                   <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <Typography style={{color: 'red'}}>
                        Billing Time Remaining
                    </Typography>
                    </div>
                </CardContent>
              </Grid>
            </Grid>
          </div>
                
          {/* <div
            className="portfolio-card-footer" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <Typography>
                    Data Last Updated: Add Here
                </Typography>
            </div> */}
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>
   );
};

    return (
        <Container style={{ marginTop: '15px' }}>
            {/* <div className="portfolio-view-control-btns-container">
                <label className="card-title">
                    <input
                        type="radio"
                        value="project"
                        checked={portfolioView === false}
                        onChange={() => setPortfolioView(false)}
                    />
                    Project View
                </label>
                <label className="card-title">
                    <input
                        type="radio"
                        value="portfolio"
                        checked={portfolioView === true}
                        onChange={() => setPortfolioView(true)}
                    />
                    Portfolio View
                </label>
            </div> */}
          {/* Header */}
          <Grid item xs={12}>
            <div className="header-container">
            <HeaderComponent
               selectedProject={selectedProject}
               projectData={projectData}
               settingsData={settingsData}
               today_date={today_date}
               selectedCompany={selectedCompany}
               companies={companies}
               handleCompanySelectChange={
                portfolioView
                ? handleCompanySelectChangeForPortfolioView
                : handleCompanySelectChangeForProjectView
                }
                filteredProjects={filteredProjects}
                handleProjectSelectChange={handleProjectSelectChange}
                setSelectedProjectId={setSelectedProjectId}
                csrfToken={csrfToken}
                selectedSnapshots={selectedSnapshots}
                handleSelectionChange={handleSelectionChange}
                handleModalClose={handleModalClose}
                selectedProjectId={selectedProjectId}
                initialSnapshots={initialSnapshots}
                portfolioView={portfolioView}
                setPortfolioView={setPortfolioView} 

                divisionOptions={divisionOptions}
                selectedDivisions={selectedDivisions}
                setSelectedDivisions={setSelectedDivisions}

                stageOptions={stageOptions}
                selectedStages={selectedStages}
                setSelectedStages={setSelectedStages}

                opsLeaderOptions={opsLeaderOptions}
                selectedOpsLeaders={selectedOpsLeaders}
                setSelectedOpsLeaders={setSelectedOpsLeaders}

                projectTypeOptions={projectTypeOptions}
                selectedProjectTypes={selectedProjectTypes}
                setSelectedProjectTypes={setSelectedProjectTypes}

                accountOptions={accountOptions}
                selectedAccounts={selectedAccounts}
                setSelectedAccounts={setSelectedAccounts}

                handlePortfolioSearch={handlePortfolioSearch}
                HUB_API_DOMAIN_NAME={HUB_API_DOMAIN_NAME}
         />
         </div>

          {/* Conditionally Render Main Content */}
          <div style={{ marginTop: '15px' }}>
            {portfolioView ? (
                // Render Portfolio View
                renderPortfolioView()
            ) : (
                // Render Project View
                renderProjectView()
            )}
            </div>
          </Grid>

           {!portfolioView && (
            <Grid style={{ marginTop: '40px' }}>
                <FooterComponent />
            </Grid>
        )}
     </Container>
  );
};
  
  export default ProjectDashboard;