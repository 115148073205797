import React, {useEffect, useState} from 'react';
import { CardContent, Typography, Grid, IconButton, Tooltip, Button } from "@material-ui/core";
import ThreePIcon from '@mui/icons-material/ThreeP';
import EmailIcon from '@mui/icons-material/Email';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '1.1rem',
    marginRight: theme.spacing(0.75),
    color: '#003865',
    [theme.breakpoints.between(800, 1775)]: {
      fontSize: '0.9rem',
    },
  },
  nameWithMargin: {
    marginRight: theme.spacing(.5), 
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      color: '#51a8ff',
    }
  },
  iconButton: {
    color: 'grey',
    '&:hover': {
      color: '#51a8ff',
    },
    [theme.breakpoints.between(800, 1775)]: {
      height: '22px',
      width: '22px',
    },
    [theme.breakpoints.down('md')]: {
      height: '5px',
      width: '5px',
    },
  }
}));

const getFirstRecordWithEmail = (roles, roleName) => {
  const role = roles.find(r => r.role === roleName);
  return role ? { name: role.name, email: role.email } : { name: '--', email: '' };
};

const ProjectTeamTable = ({ budgetData, selectedProject, reset }) => {
  const [localState, setLocalState] = useState({});
  const projectNumber = selectedProject?.project_number
  const arcoID = selectedProject?.arco_id
  const projectName = selectedProject?.name
  const classes = useStyles();
  const roles = budgetData?.project_team || [];

  useEffect(() => {
    if (reset) {
        setLocalState({});
    }
}, [reset]);

  const renderRole = (label, roleName) => {
    const { name, email } = getFirstRecordWithEmail(roles, roleName);
    const arconnectLink = email ? `https://arconnect.arco1.com/people/${email.split('@')[0]}` : '';
    return (
      <>
        <span className={classes.label}>{label}:</span>
        {email ? (
          <a href={arconnectLink} target="_blank" rel="noopener noreferrer" className={classes.nameWithMargin}>
            {name}
          </a>
        ) : (
          <span className={classes.nameWithMargin}>{name}</span>
        )}
        {email && (
          <>
            <Tooltip
                title={
                    <div className="warning-icon-tooltip-content">
                        <Typography style={{fontSize: '1rem', padding: '5px', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif'}}>{`Teams ${name}`}</Typography>
                    </div>
                }
            >
              <a className="teams-contact-button" href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`} target="_blank" rel="noopener noreferrer">
                <IconButton size="small" className={classes.iconButton}>
                  <ThreePIcon />
                </IconButton>
              </a>
            </Tooltip>
            <Tooltip
              title={
                  <div className="warning-icon-tooltip-content">
                      <Typography style={{fontSize: '1rem', padding: '5px', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif'}}>{`Email ${name}`}</Typography>
                  </div>
              }
            >
            <a className="email-contact-button" href={`mailto:${email}?subject=Question on ${projectName}`} target="_blank" rel="noopener noreferrer">
            <IconButton size="small" className={classes.iconButton}>
                  <EmailIcon />
                </IconButton>
              </a>
            </Tooltip>
          </>
        )}
      </>
    );
  };


  return (
    <CardContent>
      {budgetData && Object.keys(budgetData).length > 0 ? (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography className='project-team-typography'>
              {renderRole('PM', 'Project Manager')}
            </Typography>
            <Typography className='project-team-typography'>
              {renderRole('Super', 'Superintendent')}
            </Typography>
            <Typography className='project-team-typography'>
              {renderRole('PC', 'Project Coordinator')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className='project-team-typography'>
              {renderRole('Project Exec', 'Project Executive')}
            </Typography>
            <Typography className='project-team-typography'>
              {renderRole('Gen Super', 'General Superintendent')}
            </Typography>
            <Typography className='project-team-typography'>
              {renderRole('Accountant', 'Project Accountant')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="body1">Please select a project</Typography>
        </div>
      )}
    </CardContent>
  );
};

export default ProjectTeamTable;