import React, { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs, 
  Tab
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import BVTSettingsFields from "./BVTSettingsFieldsComponent";
import DataControlPanel from "./ProjectSettingsDataControlPanelComponent";

const ProjectSettingsModal = ({
  Component,
  projectSnapshots,
  budgetData,
  title,
  selectedSnapshots,
  onSelectionChange,
  handleModalClose,
  initialSnapshots,
  csrfToken,
  apiKey,
  apiHeader,
  HUB_API_DOMAIN_NAME,
  selectedProjectId,
  settingsData,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [bvtFields, setBvtFields] = useState({
    contract_structure: "GMP",
    job_has_deferred_tax: false,
    neg_insurance_percent: "",
    neg_fee_percent: "",
    shared_savings: false,
    shared_savings_split_percent_to_arco: "",
    max_shared_savings_amt: "",
  });
  
  // Tab Toggle btwn three sections
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Populate bvtFields when settingsData changes
  useEffect(() => {
    if (settingsData?.other_meta_data) {
      setBvtFields(settingsData.other_meta_data);
    }
  }, [settingsData]);

  const handleClickOpen = async () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // Function to close the modal AND trigger the PATCH request to update
  const handleSave = () => {
    // Prep fields for server
    const sanitizedBvtFields = {
      ...bvtFields, // spread operator brings in all other kv pairs
      job_has_deferred_tax: !!bvtFields.job_has_deferred_tax, // Dev fyi - !! forces var to convert into boolean
      shared_savings: !!bvtFields.shared_savings,
    };

    console.log("Saving BVT Fields...");
    handleModalClose(selectedSnapshots, sanitizedBvtFields);
    setOpenDialog(false);
  };

  // Keeps project_dashboard up to date
  const handleBVTFieldsChange = (updatedFields) => {
    setBvtFields(updatedFields);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen} style={{ textAlign: "right" }}>
        <SettingsIcon style={{ color: "white" }} />
      </IconButton>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          style: {
            height: "80vh",
            maxHeight: "90vh",
          },
        }}
      >
        <DialogTitle
          className="titillium-web-base"
          style={{
            fontSize: "30px",
            fontWeight: "bolder",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: '#093d61',
            color: 'white',
          }}
        >
          Project Settings
          <IconButton onClick={handleDialogClose} style={{ color: "#000" }}>
            <CloseIcon style={{color: 'white'}} />
          </IconButton>
        </DialogTitle>
        <DialogContent className="titillium-web-base" style={{overflowY: 'auto', marginTop: '-15px', backgroundColor: '#093d61', color: 'white', maxHeight: '40px'}}>
          NOTE: Changes made to these selections will update the data used by the rest
          of the dashboard. Snapshots are organized by the date the snapshot was taken and may reflect data for the month prior.
        </DialogContent>

        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="BVT Project Settings" />
          <Tab label="Budget Snapshot Selector" />
          <Tab label="Data Control Panel" />
        </Tabs>


        {/* ============= Project Settings for BVT-related items ================*/}
        <DialogContent
            style={{
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
        {selectedTab === 0 && (
          <>
          <DialogTitle>BVT Project Settings</DialogTitle>
         
            <BVTSettingsFields
              initialValues={bvtFields}
              onFieldsChange={handleBVTFieldsChange}
            />
        </>
        )}
      {/* ============= BUDGET SNAPSHOT SELECTOR ================*/}
        {selectedTab === 1 && (
        <>
          <DialogTitle>Budget Snapshot Selector</DialogTitle>
            <Component
              budgetData={budgetData}
              isModal={true}
              projectSnapshots={projectSnapshots}
              selectedSnapshots={selectedSnapshots}
              onSelectionChange={onSelectionChange}
              initialSnapshots={initialSnapshots}
            />
        </>
        )}
    {/* ============= REFRESH CONTROL COMPONENT ================*/}
      {selectedTab === 2 && (
        <>
            <DataControlPanel />
        </>
        )}
       
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            color="secondary"
            className="standard-secondary-button"
          >
            Close
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            className="standard-primary-button"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectSettingsModal;
